import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import CountrySelect from './CountrySelect';
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: '200px',         
        flexWrap: 'wrap',
        '& > *': {
            marginLeft: theme.spacing(0.5),         
        },
        '& .MuiFormControl-root': {
            minWidth: '220px',
            marginTop: '-14px'
        }
    },
    countrySelect: {
        '& .MuiAutocomplete-root': {
            margin: theme.spacing(1),
            minWidth: '200px',
            marginTop: '-14px'
        }
    },
}));


const Countries = props => {
    const classes = useStyles();
    const { selectedCountries } = props;


    const handleAdd = event => {

        if (props.onAdd)
            props.onAdd(event);
    }

    const handleDelete= data => {

        if (props.onDelete)
            props.onDelete(data);
    }


    return (
        <div className={classes.root}>
            {
                selectedCountries.map((option, index) => (
                    <Chip variant="outlined" 
                    label={option}
                    onDelete={() => handleDelete(option)}
                    icon={
                        <ReactCountryFlag countryCode={option} svg /> 
                    }
                    />
                ))
            }       
            <CountrySelect className={classes.countrySelect} onChange={handleAdd} />
        </div>
    );
}

export default Countries;
