import React, { useState, useContext, useEffect, forwardRef } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import { serverContext, appContext, postToServer, putToServer, callServer } from '../App';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import moment from 'moment';
import { useGetUsergroups } from '../customEffects';
import { securityClassificationMap, securityClassificationLookup } from '../serverReducer';



const tableIcons = {
  AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#47A3E8',
    },
  },
});


/**
 * table columns
 */
const columns = [
  { title: 'Name', field: 'name', tooltip: 'Usergroup name' },
  { title: 'Description', field: 'description', tooltip: 'Usergroup description' },
  { title: 'Classification', field: 'securityClassification', tooltip: 'Usergroup security classification', lookup: securityClassificationLookup },
  { title: 'Created', field: 'created', tooltip: 'Usergroup creation date', editable: 'never', render: rowData => { return rowData && rowData.created !== undefined ? moment(rowData.created).format("MMM Do YY, hh:mm") : '-' }, hidden: window.innerWidth < 960 },
];


const translateUserGroups = usergroups => {

  if (!Array.isArray(usergroups))
    return [];

  let i = 0;
  const tableUsers = usergroups.map(u => {
    return {
      id: i++,
      usergroupId: u._id,
      name: u.name,
      description: u.description,
      securityClassification: securityClassificationMap[u.securityClassification],
      created: u.created
    };
  });

  return tableUsers;
}


const UserGroupsTable = props => {

  const { server } = useContext(serverContext);
  const { dispatchApp } = useContext(appContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);

  useGetUsergroups();

  useEffect(() => {

    setData(translateUserGroups(server.usergroups));

  }, [server.usergroups])


  const actions = [
    {
      icon: tableIcons.DeleteIcon,
      tooltip: 'Delete Usergroup(s)',
      onClick: async (event, data) => {


        try {
          const userGroupNames = data.map(ug => ug.name);
          let userNamesStr; 
          
          if(userGroupNames.length == 1)
            userNamesStr = userGroupNames[0];
          else
            if( userGroupNames.length > 1 ) {
              userNamesStr = userGroupNames.join(',');
            }   

          await callServer('delete', `usergroups?usergroups=${userNamesStr}`, undefined, server);
        } catch (error) {
          dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error deleting usergroup(s): ${error.response ? error.response.data : error.message}` } });
        }
      }
    }
  ];


  const handleRowUpdate = (newData, oldData) => {
    return new Promise(async (resolve, reject) => {
      try {

        await putToServer(`usergroups/${newData.usergroupId}?name=${newData.name}` +
          `&description=${newData.description}` +
          `&securityClassification=${securityClassificationLookup[newData.securityClassification]}`,
          {}, server);
        resolve();
      } catch (error) {
        dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error updating usergroup: ${error.response ? error.response.data : error.message}` } });
        reject();
      }
    })
  }

  //const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={data}
        title={props.title || 'User Groups'}
        // detailPanel={rowData => {
        //   return (
        //     <Typography className={classes.message} paragraph>
        //       Usergroup
        //     </Typography>
        //   )
        // }}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow))}
        options={{
          selection: true,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EFFBFF' : '#FFF',
          }),
          headerStyle: {
            backgroundColor: '#EEE',
          }
        }}
        actions={actions}
        editable={{
          isEditable: rowData => rowData.name !== "Demo",
          isDeletable: rowData => rowData.name !== "Demo",
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {

              try {
                const usergroup = {
                  "name": newData.name,
                  "description": newData.description,
                  "securityClassification": newData.securityClassification !== undefined ? securityClassificationLookup[newData.securityClassification] : 'UNCLASSIFIED'
                }
                await postToServer('usergroups', usergroup, server);
                resolve();
              } catch (error) {
                dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error adding usergroup: ${error.response ? error.response.data : error.message}` } });
                reject();
              }
            }),
          onRowUpdate: handleRowUpdate
        }}
      />
    </MuiThemeProvider>
  );
}



export default UserGroupsTable; 
