import { io } from 'socket.io-client';


export default class WS {
    constructor(serverCfg, clientType, channel, processFunc) {

        this.socket = io({
            query: {
                clientId: serverCfg.clientId,
                user: serverCfg.username,
                status: 'connected',
                ip: serverCfg.clientIp,
                clientType: clientType,
                channel: channel,
                browserInfo: JSON.stringify(serverCfg.browserInfo)
            }
        });

        this.socket.on('connect', () => {
            console.log('WS Connect');

            if (processFunc && clientType === 'ClientApp')
                processFunc('state', 'Online');

        });

        this.socket.on('disconnect', () => {
            console.log('WS disconnect');

            if (processFunc && clientType === 'ClientApp')
                processFunc('state', 'Offline');

        });

        this.socket.onAny((event, ...args) => {
            if (processFunc)
                processFunc(event, ...args);
        });

    }


    emit(eventName, ...args) {
        this.socket.emit(eventName, ...args);
    }

    close() {
        if(this.socket)
            this.socket.close();
    }
}
