import React, { useState, Fragment, useContext } from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Title from './Title';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { serverContext, platformsContext, downloadFile } from '../App';
import UploadDlg from '../UploadDlg';

const useStyles = makeStyles({
  platformsContext: {
    flex: 1,
  },
});

export default function PlatformsBrief() {
  const { platforms } = useContext(platformsContext);
  const { server } = useContext(serverContext);
  const [uploadDlgOpen, setUploadDlgOpen] = useState(false);


  const classes = useStyles();


  function handleDownloadConfiguration() {
    downloadFile(server, `platformsConfigFile`, 'platforms.yml');
  }

  function handleUploadConfiguration() {
   
    setUploadDlgOpen(true);
  }

  const handleUploadDlgClose = () => {
  
    setUploadDlgOpen(false);
}

  return (
    <Fragment>
      <Title>Platforms</Title>
      <Typography component="p" variant="h5">
        Total: {platforms ? platforms.availablePlatforms.length : 'N/A'}
      </Typography>
      <Typography component="p" variant="h5">
        Live : {platforms ? platforms.availablePlatforms.reduce((total, p) => { return p.live ? total + 1 : total }, 0) : 'N/A'}
      </Typography>
      <Typography color="textSecondary" className={classes.platformsContext}>
        on 15 March, 2019
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/platforminfo">
              Edit platforms usergroups
            </Link>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title='Download configuration'>
            <IconButton aria-label="download" onClick={handleDownloadConfiguration}>
              <GetAppOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title='Upload configuration'>
            <IconButton aria-label="upload" onClick={handleUploadConfiguration}>
              <PublishOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <UploadDlg open={uploadDlgOpen} onClose={handleUploadDlgClose} title='Upload platform config file' />
    </Fragment>
  );
}
