import React, { useState, useContext, useEffect, forwardRef } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { appContext, missionsContext, serverContext, checkIfOnline } from '../App';
import Popover from '@material-ui/core/Popover';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Rating } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { StateCell } from './showState';
import { MissionTableToolbar } from 'stserver-frontend-comp';
import DateTimeRange from './DateTimeRange';
import * as moment from 'moment';
import GeoQueryInfo from './GeoQueryInfo';
import TagsArray from './TagsArray';
import CountriesArray from './CountriesArray';
import CountryFlag from './CountryFlag';
import SelectUsergroups from './SelectUsergroups';
import { useFetchImageFromVideoServer } from '../customEffects';


const tableIcons = {
  AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} style={{ color: 'blue' }}  />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportList: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Share: forwardRef((props, ref) => <ShareIcon {...props} ref={ref} />),
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#47A3E8',
    },
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      //       boxShadow: 'none'
    }
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  mediaRootThumbnail: {
    width: 160,
  },
  mediaRootInfo: {
    width: 260,
  },
  media: {
    height: 120,
  },
  badge: {
    paddingTop: 10,
    paddingRight: 24
  },
  tagsFilter: {
    display: 'flex',
    color: 'blue',
    padding: '0px 10px'
  },
  countryFilter: {
    display: 'flex',
    color: 'blue',
    padding: '0px 10px'
  },
  usergroupFilter: {
    display: 'flex',
    color: 'blue',
    padding: '0px 10px'
  },

}));


const MissionPopover = props => {
  const classes = useStyles();
  const { missionName, serverUrl, thumbnail, videoServerToken, update} = props;
  const { isLoading, hasError, image } = useFetchImageFromVideoServer(`${serverUrl}/videos/${missionName}/${thumbnail}`, videoServerToken, update);

    return (
    <Card className={classes.mediaRootThumbnail}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={image}
        />
      </CardActionArea>
    </Card>
  );
}

const DescriptionOrUpdateInfo = props => {

  const { data } = props;
  let updateStr = data.description;
  let color = "default";


  if (data.update !== undefined) {
    const { type, value } = data.update;
    color = 'secondary';

    switch (type) {

      case 'upload_progress':
        updateStr = `Uploaded ${Math.round(value.loaded / 1024 / 1024)} MB (size: ${Math.round(value.total / 1024 / 1024)} MB)`;
        break;

      case 'hlsDetection':
        updateStr = `Hls Detection...`;
        break;

      case 'extractingArchive':
        updateStr = `Extracting from archive...`;
        break;

      case 'hlsProcessing':
        const filename = value.path.replace(/^.*[\\\/]/, '')
        updateStr = `Segment ${filename}`;
        console.log(updateStr);
        break;

      case 'hlsProcessingComplete':
        updateStr = `Hls Processing Complete`;
        break;

      case 'ingest_start':
        updateStr = `Ingesting ${value.totalSegments} segments...`;
        break;

      case 'ingest_progress':
        updateStr = `${value.segmentName}\n Ingested ${value.currentIndex} / ${value.totalSegments} segments`;
        break;

      case 'ingest_complete':
        updateStr = `Ingest complete - ${value.totalSegments} segments / ${value.ingestedPckts} packets`;
        break;

      case 'areaCalculation':
        updateStr = value.phase === 'start' ? 'Calculate area...' : `${value.area ? value.area.toFixed(1) : 0} sq. km`
        break;

      case 'error':
          updateStr = `Error: ${value}`;
          break;

      default:
        break;
    }
  }

  return (
    <Tooltip title={`Tags: ${data.tags && data.tags.length > 0 ? data.tags : 'n/a'}`}>
      <Typography gutterBottom variant="body2" style={{ whiteSpace: 'pre-line' }} color={color} >
        {updateStr}
      </Typography>
    </Tooltip>
  );
}


const RatingInfo = props => {
  const { data } = props;

  return (
    <Rating
      value={data.rating}
      size="small"
      readOnly
    />
  )
}



// const checkExistingUserGroup = (selGroups, existingGroup) => {
//   const gr = selGroups.filter(sgr => sgr._id === existingGroup._id);
//   return gr.length > 0;
// }

/**
 * @param  {Object} props
 * admin - open in admin mode
 */
const MissionTableLocal = props => {

  const { server } = useContext(serverContext);
  const { missions } = useContext(missionsContext);
  const { app, dispatchApp } = useContext(appContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [thumbnail, setThumbnail] = useState({});
  const [toolBarCtrl, setToolBarCtrl] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState();
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queryParams] = useState(props.queryParams);

  const classes = useStyles();
  const actions = [];

  if (props.admin) {
    actions.push({
      icon: tableIcons.AddIcon,
      tooltip: 'Add Mission',
      isFreeAction: true,
      onClick: event => {
        if (checkIfOnline(server, dispatchApp)) {
          if (props.onAddMission)
            props.onAddMission(event);
        }
      }
    });
    actions.push({
      icon: tableIcons.Edit,
      tooltip: 'Edit Mission',
      isFreeAction: true,
      onClick: event => {

        if (!selectedRow)
          dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Please select mission / sensor to edit...`, severity: 'warning' } });
        else {
          if (checkIfOnline(server, dispatchApp)) {
            if (props.onEditMission && selectedRow.parentId === undefined)
              props.onEditMission(selectedRow);
          }
        }
      }
    })
    actions.push({
      icon: tableIcons.DeleteIcon,
      tooltip: 'Delete Mission(s)',
      onClick: (event, data) => {
        if (checkIfOnline(server, dispatchApp)) {
          if (props.onDeleteMissions) {
            const filterMissions = data.filter(el => !el.hasOwnProperty('parentId'));
            props.onDeleteMissions(filterMissions);
          }
        }
      }
    })
    actions.push({
      icon: tableIcons.Export,
      tooltip: 'Export / Download Mission',
      onClick: (event, data) => {
        if (checkIfOnline(server, dispatchApp)) {
          if (props.onExportMission) {
            const filterMissions = data.filter(el => !el.hasOwnProperty('parentId'));
            props.onExportMission(filterMissions);
          }
        }
      }
    });
    actions.push({
      icon: tableIcons.ExportList,
      tooltip: 'Export Mission List',
      onClick: (event, data) => {
        if (checkIfOnline(server, dispatchApp)) {
          if (props.onExportMission) {
            const filterMissions = data.filter(el => !el.hasOwnProperty('parentId'));
            props.onExportMissionList(filterMissions);
          }
        }
      }
    });
  }

  actions.push({
    icon: tableIcons.Share,
    tooltip: 'Share',
    isFreeAction: true,
    onClick: (event, data) => {
      if (!selectedRow)
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Please select mission / sensor to share...`, severity: 'warning' } });
      else {
        const url = selectedRow.hasOwnProperty('parentId') ? `${server.serverHost}/missions?mission=${selectedRow.mName}&sensors=${selectedRow.name}` : `${server.serverHost}/missions?mission=${selectedRow.name}`
        window.open(`mailto:?subject=StServer%20video%20link&body=${url}`);
      }
    }
  });

  useEffect(() => {
    let queryFilter = {};
    try {

      if (app.missionsFilter) {
        if (app.missionsFilter.tags) {
          queryFilter.tags = true;
          setSelectedTags(app.missionsFilter.tags.split(','));
        }

        if (app.missionsFilter.country_codes) {
          queryFilter.country_codes = true;
          setSelectedCountries(app.missionsFilter.country_codes.split(','));
        }

        queryFilter.time = (app.missionsFilter.start || app.missionsFilter.end) ? true : false;
        queryFilter.geo = app.missionsFilter.geometry ? true : false;
      }

      setToolBarCtrl(queryFilter);

    } catch (error) {
    }
  }, [app.missionsFilter]);

  useEffect(() => {
    if (Array.isArray(missions)) {
      setTags([...missions.filter(m => m.tags && m.tags.length > 0).map(m => m.tags)].flat().filter((x, i, a) => a.indexOf(x) === i));
    }
  }, [missions])

  /*
  * Start playback for mission / sensor passed as url query params
  * http://localhost:3000/missions?mission=DeadSea1&sensors=EO,IR
  */
  useEffect(() => {

    if (queryParams && queryParams.mission && Array.isArray(missions) && missions.length > 0) {
      let sensors = missions.filter(m => m.mName === queryParams.mission && m.parentId !== undefined);
      if (queryParams.sensors) {
        const sNames = queryParams.sensors.split(',');
        sensors = sensors.filter(s => sNames.includes(s.name));
      }
      const sensorNames = sensors.map(s => { return { m: s.mName, s: s.name, platformType: s.platformType, videoInfo: s.hlsProps }; });
      if (props.onSelectedSensors)
        props.onSelectedSensors(sensorNames);
    }

  }, [queryParams, missions])

  const handleSelectedRow = (evt, selectedRow) => {

    setSelectedRow(selectedRow)
    if (props.onSelectedSensors) {
      let sensors = [];
      if (selectedRow.parentId !== undefined) {
        sensors.push({ m: selectedRow.mName, s: selectedRow.name, platformType: selectedRow.platformType, videoInfo: selectedRow.hlsProps })
      }
      else {

        const mis = missions.filter(m => m.parentId === selectedRow.id);
        if (props.onSelectedMission)
          props.onSelectedMission(mis);

        sensors = mis.map(s => { return { m: s.mName, s: s.name, platformType: s.platformType, videoInfo: s.hlsProps }; });

        if (selectedRow.startTimeUtc) {
          dispatchApp({ type: 'SET_TIMELINE_START_TIME', payload: selectedRow.startTimeUtc });
        }

      }
      if (props.onSelectedSensors)
        props.onSelectedSensors(sensors);
    }
  }

  const handlePopoverOpen = event => {
    try {
      setThumbnail({
        mission: event.currentTarget.attributes.mission.value,
        thumbnail: event.currentTarget.title,
        accessToken: event.currentTarget.attributes.accessToken?.value
      });
      setAnchorEl(event.currentTarget);      
    } catch (error) {
      
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const applyQueryFilter = async filter => {
    setLoading(true);

    if (props.onApplyQueryFilter)
      props.onApplyQueryFilter(filter);

    setLoading(false);
  }

  const handleToolbarSelection = selection => {

    const newState = { ...toolBarCtrl };
    newState[selection] = !toolBarCtrl[selection];
    setToolBarCtrl(newState);
    let filter;

    switch (selection) {

      case 'time':

        if (!newState[selection]) {
          filter = { ...app.missionsFilter, start: undefined, end: undefined };
        }
        else {
          const timeSelection = localStorage.getItem('timeSelection');
          if (timeSelection) {
            const t = JSON.parse(timeSelection);
            filter = { ...app.missionsFilter, start: t.start, end: t.end };
          }
        }

        applyQueryFilter(filter);
        break;
      case 'geo':
        if (!newState[selection]) {
          filter = { ...app.missionsFilter, aoiMode: undefined, geometry: undefined };
          applyQueryFilter(filter);
        }

        if (props.onSelectedFilter)
          props.onSelectedFilter(selection, newState[selection]);
        break;

      case 'tags':

        if (newState[selection]) {
          const filterTagsStr = localStorage.getItem('filterTags');
          if (filterTagsStr != null && filterTagsStr.length > 0) {
            setSelectedTags(filterTagsStr.split(','));
            filter = { ...app.missionsFilter, tags: filterTagsStr };
          }
        }
        else {
          filter = { ...app.missionsFilter, tags: undefined };
        }

        applyQueryFilter(filter);

        if (props.onSelectedFilter)
          props.onSelectedFilter(selection, newState[selection]);
        break;

      case 'country_codes':

        if (newState[selection]) {
          const filterCountriesStr = localStorage.getItem('filterCountries');
          if (filterCountriesStr != null && filterCountriesStr.length > 0) {
            setSelectedCountries(filterCountriesStr.split(','));
            filter = { ...app.missionsFilter, country_codes: filterCountriesStr };
          }
        }
        else {
          filter = { ...app.missionsFilter, country_codes: undefined };
        }

        applyQueryFilter(filter);

        if (props.onSelectedFilter)
          props.onSelectedFilter(selection, newState[selection]);
        break;

      case 'usergroups':

        if (newState[selection]) {
          const filterUsergroupsStr = localStorage.getItem('filterUsergroups');
          if (server && Array.isArray(server.usergroups) && filterUsergroupsStr != null && filterUsergroupsStr.length > 0) {
            const gNames = filterUsergroupsStr.split(',');
            const sGroups = server.usergroups.filter(g => gNames.includes(g.name));
            setSelectedUserGroups(sGroups);
            filter = { ...app.missionsFilter, usergroups: filterUsergroupsStr };
          }
        }
        else {
          filter = { ...app.missionsFilter, usergroups: undefined };
        }

        //    applyQueryFilter(filter);

        if (props.onSelectedFilter)
          props.onSelectedFilter(selection, newState[selection]);
        break;

      default:
        break;
    }
  }

  const handleSelectTime = async selectedTimes => {

    let filter = { ...app.missionsFilter, start: selectedTimes.start, end: selectedTimes.end };
    dispatchApp({ type: 'UPDATE_MISSIONS_FILTER', payload: filter });

    localStorage.setItem('timeSelection', JSON.stringify(selectedTimes));

    applyQueryFilter(filter);
  }

  const handleTagsChange = async selectedTags => {

    const tagsFilterStr = selectedTags.join(',');
    const filter = { ...app.missionsFilter, tags: tagsFilterStr };
    dispatchApp({ type: 'UPDATE_MISSIONS_FILTER', payload: filter });

    setSelectedTags(selectedTags);
    localStorage.setItem('filterTags', tagsFilterStr);

    applyQueryFilter(filter);
  }

  const handleCountriesAdd = async newCountry => {

    if (!newCountry || selectedCountries.includes(newCountry.country))
      return;

    const allSelectedCountries = [...selectedCountries, newCountry.country];
    const countriesFilterStr = allSelectedCountries.join(',');
    const filter = { ...app.missionsFilter, country_codes: countriesFilterStr };
    dispatchApp({ type: 'UPDATE_MISSIONS_FILTER', payload: filter });

    setSelectedCountries(allSelectedCountries);
    localStorage.setItem('filterCountries', countriesFilterStr);

    applyQueryFilter(filter);
  }

  const handleCountriesDelete = async country => {
    const allSelectedCountries = selectedCountries.filter(c => c !== country);
    const countriesFilterStr = allSelectedCountries.join(',');
    const filter = { ...app.missionsFilter, country_codes: countriesFilterStr };
    dispatchApp({ type: 'UPDATE_MISSIONS_FILTER', payload: filter });

    setSelectedCountries(allSelectedCountries);
    localStorage.setItem('filterCountries', countriesFilterStr);

    applyQueryFilter(filter);
  }

  const handleUserGroupsChange = ugs => {

    const usergroupFilterStr = ugs.map(ug => ug.name).join(',');
    const filter = { ...app.missionsFilter, usergroups: usergroupFilterStr };
    dispatchApp({ type: 'UPDATE_MISSIONS_FILTER', payload: filter });

    setSelectedUserGroups(ugs);
    localStorage.setItem('filterUsergroups', usergroupFilterStr);

    applyQueryFilter(filter);
  }



  const open = Boolean(anchorEl);

  const getColumns = () => {
    return (
      [
        {
          title: 'State', field: 'state', width: '5%', filtering: true,
          render: rowData =>
            <div title={`${rowData.thumbnail}`}
              mission={`${rowData.name}`}
              accessToken={rowData.accessToken}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}>
              <StateCell state={rowData.state} missionName={rowData.name} serverUrl={server.serverHost} thumbnail={rowData.thumbnail} videoServerToken={rowData.accessToken} update={Date.now()} />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <MissionPopover serverUrl={server.serverHost} missionName={thumbnail.mission} thumbnail={thumbnail.thumbnail} videoServerToken={thumbnail.accessToken} update={rowData.update} />
              </Popover>
            </div>
        },
        {
          title: 'Name', field: 'name', render: rowData =>
            <Tooltip title={`Created on ${moment(rowData.creationTime).format("MMM DD YYYY, h:mm")}`}>
              <strong>{`${rowData.name}`}</strong>
            </Tooltip>
        },
        {
          title: 'Description', field: 'description',
          render: rowData => <DescriptionOrUpdateInfo data={rowData} />
        },
        { title: 'Platform', field: 'platform', hidden: window.innerWidth < 960 },
        { title: '', field: 'country_code', tooltip: `Country flag`, width: '4%', render: rowData => rowData.country_code && <CountryFlag country_name={rowData.country_name} country_code={rowData.country_code} /> },
        {
          title: 'Start Time', field: 'startTime', hidden: window.innerWidth < 960,
          render: rowData => { return (rowData.startTime ? rowData.startTime : 'N/A') }
        },
        {
          title: 'End Time', field: 'endTime', hidden: window.innerWidth < 960,
          render: rowData => { return (rowData.endTime ? rowData.endTime : 'N/A') }
        },
        {
          title: 'Rating', field: 'rating', hidden: window.innerWidth < 960,
          render: rowData =>
            <RatingInfo data={rowData} />
        },
        { title: 'Views', field: 'views', width: '4%', hidden: window.innerWidth < 960 }
      ]
    )
  }



  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <MaterialTable className={classes.root}
          icons={tableIcons}
          columns={getColumns()}
          data={props.admin ? missions : missions.filter(item => (item.state !== 'Created' && item.parentState !== 'Created'))}
          title={<MissionTableToolbar missions={missions} controls={toolBarCtrl} missionsFilter={props.missionsFilter} onSelection={handleToolbarSelection} />}
          parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          onRowClick={handleSelectedRow}
          options={{
            sorting: true,
            cellStyle: { padding: '0.4em' },
            headerStyle: { padding: '0.4em' },
            selection: props.admin,
            selectionProps: rowData => ({
              disabled: rowData.parentId !== undefined,
              color: 'secondary'
            }),
            rowStyle: rowData => ({
              backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
            filtering: toolBarCtrl.filter
          }}
          actions={actions}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                {
                  toolBarCtrl.time &&
                  <div style={{ padding: '0px 10px' }}>
                    <DateTimeRange selectTime={app.missionsFilter} onTimeSelected={handleSelectTime} />
                  </div>
                }
                {
                  toolBarCtrl.geo &&
                  <div style={{ padding: '0px 10px' }}>
                    <GeoQueryInfo />
                  </div>
                }
                {
                  toolBarCtrl.tags &&
                  <div className={classes.tagsFilter}>
                    <Typography noWrap variant="overline" display="block" gutterBottom>Select tags</Typography>
                    <TagsArray tags={tags} selectedTags={selectedTags} onChange={(event, newValue) => handleTagsChange(newValue)} />
                  </div>
                }
                {
                  toolBarCtrl.country_codes &&
                  <div className={classes.countryFilter}>
                    <Typography noWrap variant="overline" display="block" gutterBottom>Select country</Typography>
                    <CountriesArray selectedCountries={selectedCountries} onAdd={handleCountriesAdd} onDelete={handleCountriesDelete} />
                  </div>
                }
                {
                  toolBarCtrl.usergroups &&
                  <div className={classes.usergroupFilter}>
                    <Typography noWrap variant="overline" display="block" gutterBottom>Select usergroups</Typography>
                    <SelectUsergroups selectedUserGroups={selectedUserGroups} onUserGroupsChange={handleUserGroupsChange} />
                  </div>
                }
              </div>
            )
          }}
        />
        {loading && <LinearProgress />}
      </MuiThemeProvider>
    </div>
  );
}


export default MissionTableLocal;