import React, { useContext, useState, useMemo, useCallback, forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { serverContext, appContext, uploadToServer } from './App';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};




const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" {...props} ref={ref} />;
})

const DropzoneLicense = props => {

    const [files2Upload /*, setLicenseFiles*/] = useState([]);

    const onDrop = useCallback(acceptedFiles => {

        if (acceptedFiles && props.onFileDrop)
            props.onFileDrop(acceptedFiles);

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({ onDrop, multiple: true });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...(acceptedFiles ? acceptedFiles : {}),
    }), [isDragActive, isDragReject]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a file, or click to select it</p>
            </div>
            <aside>
                <ul>{files2Upload}</ul>
            </aside>
        </div>
    )
}



function UploadDlg(props) {
    const { server /*, dispatchServer*/ } = useContext(serverContext);
    const { dispatchApp } = useContext(appContext);



    const handleLicFileDrop = async files => {

        try {
            const res = await uploadToServer(`upload/platformsConfig`, 'platforms', files, server);

            const validation = res.data;
            if (validation.valid)
                dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Config file uploaded successfully.` } });
            else
                dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error uploading config file or file is invalid`, severity: 'error' } });

            // const info = await fetchFromServer('licenseInfo', server);
            // setLicenseInfo(info.data);

            // dispatchServer({ type: 'UPDATE_LICENSE_VALID', payload: info.data });

        } catch (error) {

            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `File upload error - ${error.response ? error.response.data : error.message}`, severity: 'error' } });
        }
    }


    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.onClose()}
        >
            <DialogTitle id="license-dialog-slide-title">
                {props.title || 'Upload file'}
            </DialogTitle>
            <DialogContent>
                <DropzoneLicense onFileDrop={handleLicFileDrop} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.onClose()} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadDlg;