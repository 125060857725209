import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { serverContext } from '../App';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    }     
  }));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    disableAutoClose: true, // prevent the menu from closing; 
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 260,
        },
    },
};


/**
 * checkExistingUserGroup
 * @param {Array} selGroups 
 * @param {Object} existingGroup 
 */
const checkExistingUserGroup = (selGroups, existingGroup) => {

    if (selGroups === undefined || existingGroup === undefined)
        return false;

    const gr = selGroups.filter(sgr => sgr._id === existingGroup._id);
    return gr.length > 0;
}


/**
 * 
 * @param {Object} props 
 */
const SelectUsergroups = props => {

    const { server } = useContext(serverContext);

    const classes = useStyles();

    const handleGroupCheckedChange = event => {
        event.stopPropagation();
        try {
            let updatedGroups;
            if (event.target.checked) {

                const gr = server.usergroups.filter(sgr => sgr._id === event.target.name);
                if (gr.length > 0) {
                    updatedGroups = [...props.selectedUserGroups, ...gr];
                }
            }
            else {

                updatedGroups = [...props.selectedUserGroups.filter(g => g._id !== event.target.name)]
            }

            if (props.onUserGroupsChange) {
                props.onUserGroupsChange(updatedGroups);
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (

        <Select
            className={classes.root}
            labelId="usergroups-label"
            id="usergroups-label"
            multiple
            value={props.selectedUserGroups}
            MenuProps={MenuProps}
            renderValue={selected => selected.map(g => g.name).join(', ')}
        >
            {server && server.usergroups ?
                server.usergroups.map(g => (
                    <MenuItem key={g._id} value={g}>
                        <Checkbox name={g._id} disabled={!props.canDeleteDemo && g.name === 'Demo'} checked={checkExistingUserGroup(props.selectedUserGroups, g)} onChange={handleGroupCheckedChange} />
                        <ListItemText primary={g.name} />
                    </MenuItem>
                )) : []}
        </Select>
    )
}

SelectUsergroups.propTypes = {
    canDeleteDemo: PropTypes.any,
    selectedUserGroups: PropTypes.array,
    onUserGroupsChange: PropTypes.func,
};


export default SelectUsergroups;