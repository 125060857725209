import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { serverContext, fetchFromServer } from './App';


export const useServerGet = (url, server, updated) => {
    const [data, setData] = useState(null);
    const [errorRet, setErrorRet] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const res = await axios(`${server.serverHost}/api/${url}`, {
                    headers: { 'Authorization': `Basic ${server.token}` }
                });
                if (res && res.data)
                    setData(res.data);
            } catch (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    setErrorRet(`Server Error: ${error.response.status} - ${error.response.statusText}`);
                } else if (error.request) {
                    // The request was made but no response was received
                    setErrorRet('No response received from the server.');
                } else {
                    // Something else happened while setting up the request
                    setErrorRet('An error occurred while making the request.');
                }
            } finally {
                setIsLoading(false);
            }
        };
        if (server.serverHost)
            fetchData();
    }, [server, server.serverHost, url, updated]);
    return { data, errorRet, isLoading };
};

/**
 * GetPlatforms
 */
// export const useGetPlatforms = () => {
//     const { server } = useContext(serverContext);
//     const { dispatchPlatforms } = useContext(platformsContext);

//     useEffect(() => {
//         const fetch = async () => {

//             if (server.serverHost) {
//                 try {
//                     const servePlatforms = await fetchFromServer('platforms', server);
//                     dispatchPlatforms({ type: 'SET_PLATFORMS', payload: servePlatforms.data });
//                 } catch (error) {
//                     dispatchPlatforms({ type: 'SET_PLATFORMS', payload: null });
//                 }
//             }
//         }

//         fetch();
//     }, [server.serverHost, server, dispatchPlatforms]);
// };



export const useGetUsergroups = async () => {

    const { server, dispatchServer } = useContext(serverContext);

    useEffect(() => {
        const fetch = async () => {
            if (server.serverHost && server.usergroups == null) {
                try {
                    const res = await fetchFromServer('usergroups', server);
                    dispatchServer({ type: 'SET_USERGROUPS', payload: res.data });

                } catch (error) {
                    dispatchServer({ type: 'SET_USERGROUPS', payload: null });
                }
            }
        };
        fetch();
    }, [server.serverHost, server.usergroups, dispatchServer, server]);
};


export const useGetUsers = async () => {

    const { server, dispatchServer } = useContext(serverContext);

    useEffect(() => {
        const fetch = async () => {
            if (server.serverHost && server.users == null) {
                try {
                    const res = await fetchFromServer('users', server);
                    dispatchServer({ type: 'SET_USERS', payload: res.data });

                } catch (error) {
                    dispatchServer({ type: 'SET_USERS', payload: null });
                }
            }
        };
        fetch();
    }, [server.serverHost, server.users, dispatchServer, server]);
};



export const useFetchImageFromVideoServer = (imageUrl, accessToken, update) => {
    const [image, setImage] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        async function fetchImage(accessToken) {

            const headers = accessToken && {
                headers: {
                    'authorization': `Bearer ${accessToken}`,
                }
            }

            const response = await fetch(imageUrl, headers);
            const blob = await response.blob();
            const dataUrl = await new Promise(resolve => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(blob);
            });
            return dataUrl;
        }

        async function fetchData() {

            try {
                const dataUrl = await fetchImage(accessToken);
                setImage(dataUrl);
            }
            catch (error) {
                console.error('Error fetching image:', error);
                setHasError(true);
            }
        }
        fetchData();

        setIsLoading(false);

    }, [imageUrl, accessToken, update]);


    return { isLoading, hasError, image };
}
