import React, { useState, useEffect, forwardRef } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import update from 'immutability-helper';
import MaterialTable from 'material-table'
import Dropzone from './dropzone';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';



const tableIcons = {
    AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
    DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    CloudUploadOutlinedIcon: forwardRef((props, ref) =>
        <div id="open-btn-id" >
            <CloudUploadOutlinedIcon {...props} ref={ref} />
        </div>
     )
};


const theme = createTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#47A3E8',
        },
    },
});


const columnsByMode = editMode => {

    return editMode ? [
        { title: 'Name', field: 'name', editable: 'never' },
        { title: 'Description', field: 'description' },
        { title: 'Type', field: 'type', editable: 'never' }
    ] :
        [
            { title: 'Name', field: 'name' },
            { title: 'Description', field: 'description' },
            { title: 'Type', field: 'type' }
        ]
}

let showDragDrop = false;

/**
 * @param  {} props
 * admin - open in admin mode
 */
export default function SensorTable(props) {

    const [sensors, setSensors] = useState(props.sensors);
    const [selectedRow, setSelectedRow] = useState(null);
    const [sensorsUpdated, setSensorsUpdated] = useState(false);
   // const showDragDrop = useRef();

    const actions = [];
    if (props.admin) {

        actions.push({
            icon: tableIcons.AddIcon,
            tooltip: 'Add Sensor',
            isFreeAction: true,
            onClick: (event, data) => {
                setSensors(update(sensors, {
                    $push: [{
                        name: 'EO',
                        description: 'Sensor',
                        type: 'video',
                        files: []
                    }]
                }));
            }
        });

        actions.push({
            icon: tableIcons.DeleteIcon,
            tooltip: 'Delete Sensor(s)',
            isFreeAction: true,
            onClick: event => {
                if (selectedRow) {
                    setSensors(update(sensors, { $splice: [[selectedRow.tableData.id, 1]] }));
                    setSensorsUpdated(true);
                }
            }
        })
    }

    /**
     * Open Drag & drop with some delay 
     */
    useEffect(() => {  
        if(!showDragDrop) {
            setTimeout(()=>{
                const openBtn = document.getElementById('open-btn-id');
                if (openBtn) {
                    openBtn.click();
                }
            }, 500); 
            showDragDrop = true;
        }            
    }, []);



    useEffect(() => {
        if (sensorsUpdated && props.onChange) {
            props.onChange(sensors);
            setSensorsUpdated(false);
        }
    }, [sensorsUpdated]);


    const handleFileDrop = sensor => {

        const index = sensors.findIndex(s => s.name === sensor.name);
        setSensors(update(sensors, { [index]: { $set: sensor } }));
        setSensorsUpdated(true);
    }

    return (
        <React.Fragment>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    icons={tableIcons}
                    columns={columnsByMode(props.editMode)}
                    data={sensors}
                    title="Sensors"
                    actions={actions}
                    onRowClick={((evt, selectedRow, togglePanel) => {
                        setSelectedRow(selectedRow);
                        togglePanel()
                    }
                    )}
                    editable={{
                        //TODO:
                        // Currently, only enable add button in Create mission mode, not edit. Add functionality here.
                        // onRowAdd: !props.editMode ? newData =>
                        //     new Promise((resolve, reject) => {
                        //         setSensors(update(sensors, { $push: [newData] }));
                        //         resolve();
                        //         setSensorsUpdated(true);
                        //     }) : undefined,
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                const index = sensors.indexOf(oldData);
                                setSensors(update(sensors, { [index]: { $set: newData } }));
                                resolve();
                                setSensorsUpdated(true);
                            })
                    }}
                    detailPanel={[
                        {
                            tooltip: 'Select files to upload',
                            icon: tableIcons.CloudUploadOutlinedIcon,
                            render: rowData => {
                                return (
                                    <Dropzone sensor={rowData} editMode={props.editMode} onFileDrop={handleFileDrop} />
                                )
                            }
                        }
                    ]}
                    options={{
                        // actionsColumnIndex: -1,                       
                        rowStyle: rowData => ({
                            backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                        })                    
                    }}

                />
            </MuiThemeProvider>
        </React.Fragment>
    );
}
