import React, { useContext } from 'react';
import { TileLayer, LayersControl } from 'react-leaflet';
import { useServerGet } from '../customEffects';
import { serverContext} from '../App';

const { BaseLayer } = LayersControl;

// const mapLayers =
//     [
//         {
//             "title": "OpenStreetMap",
//             "tileLayer": "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
//             "attribution": "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a>"
//         },
//         {
//             "title": "OpenTopoMap",
//             "tileLayer": "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
//             "attribution": "Map data: &copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>, <a href=\"http://viewfinderpanoramas.org\">SRTM</a> | Map style: &copy; <a href=\"https://opentopomap.org\">OpenTopoMap</a> (<a href=\"https://creativecommons.org/licenses/by-sa/3.0/\">CC-BY-SA</a>)"
//         },
//         {
//             "title": "Esri_WorldStreetMap",
//             "tileLayer": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
//             "attribution": "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
//         },
//         {
//             "title": "Esri_WorldTopoMap",
//             "tileLayer": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
//             "attribution": "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
//         },
//         {
//             "title": "Esri WorldImagery",
//             "tileLayer": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
//             "attribution": "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
//         },
//         {
//             "title": "Google",
//             "tileLayer": "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
//             "attribution": "Google"
//         },
//         {
//             "title": "Offline maps",
//             "tileLayer": "http://localhost:5000/{z}/{x}/{y}.png",
//             "attribution": "Offline Tiles"
//         }
//     ]



const MapLayersControl = (props) => {
    const { server } = useContext(serverContext);
    let baseLayerMap = localStorage.getItem("baseLayerMap");
    if (!baseLayerMap)
        baseLayerMap = "OpenStreetMap";

    const { data: mapCfg, hasError, isLoading } = useServerGet('mapConfig', server);

    return (
        <LayersControl position="topright">

            {mapCfg && mapCfg.layers.map(layer => (
                <BaseLayer key={layer.title} name={layer.title} checked={baseLayerMap === layer.title}>
                    <TileLayer attribution={layer.attribution} url={layer.tileLayer} />
                </BaseLayer>
            ))}

        </LayersControl>
    );
}


export default MapLayersControl;