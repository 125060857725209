/* eslint-disable */


import React, {useMemo, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';


const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};



export default function Dropzone(props) {

  const onDrop = useCallback(acceptedFiles => {

    //console.log(acceptedFiles[0].type)

    const newSensor = JSON.parse(JSON.stringify(props.sensor));
    if (!newSensor.files)
      newSensor.files = [];

    acceptedFiles.forEach(file => {
      const existingFiles = newSensor.files.filter(f => f.path = file.path);

      if (!existingFiles.length > 0)
        newSensor.files.push(file);
    })

    if (props.onFileDrop)
      props.onFileDrop(newSensor);

  }, [])


  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({ onDrop, multiple: true, accept: ['text/plain', 'text/vnd.qt.linguist', 'video/mp2t', /* 'video/mp4', 'text/csv', '.csv', '.bin', 'application/x-binary', 'application/vnd.ms-excel',*/ 'application/json',  'application/zip', 'application/x-zip', 'application/x-zip-compressed', 'application/x-winzip' ] });  // FIXME: zip currently disabled as archived import is not properly implemented


  const files = props.sensor.files ? props.sensor.files.map(file => <li key={file.path}>{file.path}</li>) : null;

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}), 
    ...(acceptedFiles ? acceptedFiles : {}), 
  }), [
    isDragActive,
    isDragReject
  ]);


  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop sensor's assets here, or click to select files</p>
        <em>(Only *.ts files, *.zip HLS archives and manifests will be accepted)</em>
      </div>
      <aside>     
        <ul>{files}</ul>
      </aside>
    </div>
  );
}