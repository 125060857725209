import React, { useContext, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Hyperlink from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import DuoIcon from '@material-ui/icons/Duo';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from '@material-ui/core/Tooltip';
import { MainListItems, SecondaryListItems } from './Components/listItems';
import Login from './Components/Login';
import Register from './Components/Register';
import Admin from './admin';
import Missions from './missions';
import Platforms from './platforms';
import UserMessages from './userMessages';
import Users from './users';
import UserActivity from './userActivity';
import UserGroups from './userGroups';
import ServerBackupsTable from './Components/ServerBackupsTable';
import PlatformInfo from './platformInfo';
import About from './about';
import ShowState from './Components/showState';
import { serverContext } from './App';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import OnlineUsersCount from './Components/OnlineUsersCount';
import { openConferenceWnd } from './Components/ConferenceWnd';
import CreateConfDlg from './CreateConfDlg.js';


const drawerWidth = 164;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& .MuiContainer-maxWidthLg': {
      maxWidth: '100%'
    },
    '& .MuiContainer-root': {
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 8,
      paddingRight: 8
    },

  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 10,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,

  },
  logo: {
    flexGrow: 1,
    maxWidth: 100,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 90,
    },
  },
  logInBttn: {
    flexGrow: 1,
    marginLeft: -10,
  },
  conferenceBttn: {
    marginLeft: 10,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(0),
    },
  },
  // appBarSpacer: theme.mixins.toolbar,
  appBarSpacer: {
    minHeight: 50,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

}));


const isMobile = () => {
  return (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i));
}


/**
 * Show Copyright
 */
const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Hyperlink color="inherit" href="https://impleotv.com/">
        IMPLEOTV SYSTEMS LTD.
      </Hyperlink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

/**
 * Returns saved side menu state from local storage
 */
const getSavedMenuStateOpened = () => {
  const menuState = localStorage.getItem('sideMenuOpened');

  return menuState ? JSON.parse(menuState) : isMobile == null;
}

/**
 * Main page
 */
const Main = () => {
  const classes = useStyles();
  const { server } = useContext(serverContext);
  const [openConfDlg, setOpenConfDlg] = useState();
  const [open, setOpen] = useState(getSavedMenuStateOpened);

  const confWnd = useRef();

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem('sideMenuOpened', 'true');
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('sideMenuOpened', 'false');
  };


  const handleConfDlgClose = (roomInfo, token) => {
    setOpenConfDlg(false);

    if (roomInfo && token && token) {

      let confServerUrl;

      if(server.conferenceServerUrl) {
        confServerUrl = server.conferenceServerUrl;
      }
      else {
        confServerUrl = server.useReverseProxy ? `${server.serverUrl.protocol}//${server.serverUrl.hostname}/livecall` : `${server.serverUrl.protocol}//${server.serverUrl.hostname}:${server.conferenceServerPort}`
      }
        
      confWnd.current = openConferenceWnd(confServerUrl, server.lk_url, token);
    }
  }

  const handleConferenceOpen = () => {
    setOpenConfDlg(true);
  }


  const userRoleAdmin = server.userRole === 'superAdmin' || server.userRole === 'admin' || server.userRole === 'groupAdmin';

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} >
          <Toolbar variant="dense" className={classes.toolbar} >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <img src="ImpleoLogo.png" alt="logo" className={classes.logo} />
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            </Typography>
            <ShowState state={server.state} hideStateText={true} />
            <OnlineUsersCount />
            <Tooltip title='Conferences'>
              <IconButton           
                color="inherit"
                aria-label="open conference"
                onClick={handleConferenceOpen}
                className={classes.conferenceBttn}
              >  <Badge badgeContent={server.conferencesCount ? server.conferencesCount.length : 0} color="secondary">
                  <DuoIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Link to="/usermessages" style={{ color: '#FFF' }}>
              <Tooltip title='Messages'>
                <IconButton color="inherit" >
                  <Badge badgeContent={server.usermessages ? server.usermessages.length : 0} color="secondary">
                    <MailIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Link>
            <Tooltip title='Alarms'>
              <IconButton color="inherit">
                <Badge badgeContent={0} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Link to="/login" style={{ color: '#FFF' }}>
              <Tooltip title={`Logged in as ${server.username}`}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  className={classes.logInBttn}
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
            </Link>
          </Toolbar>
        </AppBar>
        {openConfDlg && <CreateConfDlg onClose={handleConfDlgClose} />}
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <MainListItems userRoleAdmin={userRoleAdmin} />
          <Divider />
          <SecondaryListItems />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/missions" />
              </Route>
              <Route path="/missions" render={props => (
                <Missions mode={'vod'} search={props.location.search} />
              )} />
              <Route path="/live" render={props => (
                <Missions mode={'live'} search={props.location.search} />
              )} />
              {userRoleAdmin &&
                <Route path="/platforms">
                  <Platforms />
                </Route>
              }
              {userRoleAdmin &&
                <Route path="/admin">
                  <Admin />
                </Route>
              }
              <Route path='/help' component={() => { window.location = server.serverHost ? `${server.serverHost}/help` : '/'; return null; }} />
              <Route path="/about">
                <About />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/usermessages">
                <UserMessages />
              </Route>
              <Route path="/users">
                <Users />
              </Route>
              <Route path="/useractivity">
                <UserActivity />
              </Route>
              <Route path="/usergroups">
                <UserGroups />
              </Route>
              <Route path="/backups">
                <ServerBackupsTable />
              </Route>
              <Route path="/platforminfo">
                <PlatformInfo />
              </Route>
              <Route path='/stsupervisor' component={() => { window.location = server.supervisorUrl ? `${server.supervisorUrl}/` : '/'; return null; }} />
            </Switch>

            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </Router>
  );
}


export default Main;