import React from 'react';
import Grid from '@material-ui/core/Grid';
import UsersTable from './Components/UsersTable';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },   
}));

export default function Users() {

    const classes = useStyles();

    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item >
                <UsersTable  />
            </Grid>
        </Grid>
    );
}