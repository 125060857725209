import React, { useState, useEffect } from 'react';
import MapIcon from '@material-ui/icons/Map';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import DownloadIcon from '@material-ui/icons/GetApp';
import SubjectIcon from '@material-ui/icons/Subject';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';


const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

export const getStoredMissionControlsSettings = () => {

    const missionControlsCfg = localStorage.getItem('missionControlsCfg');
    return missionControlsCfg != null ? JSON.parse(missionControlsCfg) : ['gridMap'];
}


/**
 * MissionPlaybackToolbar - the toolbar in the Sensor expansion panel. Shows mission presentation and control buttons.
 * 
 * @param  {} props
 */
const MissionPlaybackToolbar = props => {

    const [openControls, setOpenControls] = useState([]);

    useEffect(() => {
        const cfg = getStoredMissionControlsSettings();
        setOpenControls(cfg);
        if (props.onMissionControlsCfgLoaded)
            props.onMissionControlsCfgLoaded(cfg);
    }, [])


    const handleOpenControls = (event, newControls) => {
        setOpenControls(newControls);

        if (props.onControlSelectionChange)
            props.onControlSelectionChange(newControls);

        localStorage.setItem('missionControlsCfg', JSON.stringify(newControls));
    };

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
                <StyledToggleButtonGroup size="small" value={openControls} onChange={handleOpenControls} aria-label="open-controls" >
                    {
                        props.mode !== 'live' &&
                        <ToggleButton value='timeline' aria-label="timeline" >
                            <Tooltip title="Mission Timeline">
                                <SubjectIcon />
                            </Tooltip>
                        </ToggleButton>
                    }
                    <ToggleButton value='gridMap' aria-label="gridMap" >
                        <Tooltip title="Grid Map">
                            <MapOutlinedIcon />
                        </Tooltip>
                    </ToggleButton>

                    <ToggleButton value='floatingMap' aria-label="floatingMap" >
                        <Tooltip title="Floating Map">
                            <MapIcon />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="klv" aria-label="klv">
                        <Tooltip title="Klv metadata">
                            <AccountTreeIcon />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="bookmarks" aria-label="bookmarks">
                        <Tooltip title="Bookmarks">
                            <BookmarksIcon />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="exportVideo" aria-label="exportVideo">
                        <Tooltip title="Export Video">
                            <DownloadIcon />
                        </Tooltip>
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Grid>
        </Grid>
    );
}

export default MissionPlaybackToolbar;