import React from 'react';
import Grid from '@material-ui/core/Grid';
import UserMessageTable from './Components/UserMessageTable';


export default function UserMessages() {

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <UserMessageTable />
            </Grid>
        </Grid>
    );
}