

export const openConferenceWnd = (confServerUrl, lk_url, token, e2eePassphrase) => {

  // Define the parameters you want to pass
  const parameters = {
    lkUrl: lk_url,
    token: token,
    e2eePassphrase: e2eePassphrase,
  };

  // Convert the parameters into a query string
  const queryString = Object.keys(parameters)
    .map((key) => `${key}=${encodeURIComponent(parameters[key])}`)
    .join('&');

  // Create the URL with parameters
  const urlWithParameters = `${confServerUrl}?${queryString}`;

  // Open the popup window
  const popup = window.open(urlWithParameters, 'ConferenceWnd', 'width=600,height=400');

  // Optionally, you can focus the popup window if needed
  if (popup) {
    popup.focus();
  }

  return popup;
}
