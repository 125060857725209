import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),          
        },
        '& .MuiFormControl-root': {
            minWidth: '180px',   
            margin: theme.spacing(1),          
        },      
        marginTop: '-20px'
    }
}));


const Tags = props => {
    const classes = useStyles();
    const { tags, selectedTags } = props;


    const handleOnChange = (event, value, reason) => {
        if (props.onChange)
            props.onChange(event, value, reason);
    }

    return (
        <Autocomplete
            className={classes.root}
            size="small"
            multiple           
            options={tags || []}
            defaultValue={selectedTags}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField {...params} variant="standard" label="Tags" placeholder="Add..." />
            )}
            onChange={handleOnChange}
        />
    );
}

export default Tags;
