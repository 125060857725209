import React, { useState, useEffect, useContext, useRef, useMemo, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import Paper from '@material-ui/core/Paper';
import ReactResizeDetector from 'react-resize-detector';
import { serverContext, appContext, fetchFromServer, getFromServer, postToServer, callServer, downloadFile } from '../App';
import UavMap, { MapCenterMode, MapControlsMode, AoiMode } from '../Map/UavMap';
import FloatingWnd from './FloatingWnd';
import VideoCoverageChart from './VideoCoverageChart';
import { StCutControl, StBookmarksTimeline, KlvView, SensorPlayer, SensorPlayerToolbar, useMission, useSensors, useSyncTimeline, timelineToClipPos, getNextClipIndex } from 'stserver-frontend-comp';
import CreateBookmarkDlg from '../CreateBookmarkDlg';
import BookmarksDlg from '../BookmarksDlg';
import * as moment from 'moment';
import CurrentMissionTimeline from './CurrentMissionTimeline';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  playerWrapper: {
    background: grey[400],
    padding: 1
  },
  masterPlayerWrapper: {
    background: blue[200],
    padding: 1
  },
  videoCoverageChart: {
    background: 'white',
    padding: 2,
    marginTop: 4,
    height: 80
  },
  bookmarksChart: {
    position: 'relative',
    background: 'green',
    padding: 2,
    marginTop: 4,
    height: 40
  },
  playerBottomControls: {
    position: 'static',
    background: 'white',
    marginTop: 4,
    marginBottom: 4,
  },
  timeline: {
    marginTop: theme.spacing(0.5),
  },

}));

const getLastLocation = type => {

  const lastFloatingWinLocation = localStorage.getItem(`lastFloatingWinLocation-${type}`);
  return lastFloatingWinLocation != null ? JSON.parse(lastFloatingWinLocation) : {
    width: 160,
    height: 240,
    x: 10,
    y: 10
  };
}

/**
 * 
 * @param {String} type 
 * @param {Object} location 
 */
const saveLastLocation = (type, location) => {
  localStorage.setItem(`lastFloatingWinLocation-${type}`, JSON.stringify(location));
}

const getLastMapProps = () => {

  const lastMapProp = localStorage.getItem('lastMapProps');
  let mProps = lastMapProp != null ? JSON.parse(lastMapProp) : {
    center: [37.71478815132924, -122.44674682617188],
    zoom: 12,
    mapCenterMode: MapCenterMode.Platform,
    mapControlsMode: MapControlsMode.None,
    aoiControlsMode: AoiMode.FrameCenter
  };

  if (mProps.mapCenterMode === undefined)
    mProps.mapCenterMode = MapCenterMode.Platform;

  if (mProps.aoiControlsMode === undefined)
    mProps.aoiControlsMode = AoiMode.FrameCenter;

  return mProps;
}

const saveLastMapProps = mapProps => {
  localStorage.setItem('lastMapProps', JSON.stringify(mapProps));
}


const getLiveMode = props => {
  if (props.useLiveLowLatency)
    return "lowLatency";
  else {
    if (props.useWebRtcVideo)
      return "webRtc";
    else
      if (props.useLiveRtspHls)
        return "highQuality";
      else
        return "highQualityDvr";
  }
}


/**
 * Calculates player width based on the type of controls opened in the grid
 * @param {Boolean} gridMapOpen 
 * @param {Number} containerWidth 
 * @param {Number} sensorsCount 
 */
const calculatePlayerWidth = (gridMapOpen, containerWidth, sensorsCount) => {
  //console.log(`container width ${containerWidth}`);

  if (containerWidth < 600)
    return containerWidth * 0.98;

  let k = 0.48;

  if (gridMapOpen) {
    if (sensorsCount === 0)
      k = 0.6;
  }
  else {
    if (sensorsCount === 1)
      k = 0.72;
  }

  return containerWidth * k;
};


/**
 * 
 * @param {Object} mission 
 * @param {Object} sensors 
 * @returns parsed times for mission and sensor clips
 */
const getMissionClipsPosition = (mission, sensors) => {
  if (mission && sensors) {

    const missionStart = new Date(mission.startTime).getTime();

    return sensors.map(s => {
      const sClips = s.clips.map(c => {
        return {
          start: (new Date(c.startTime).getTime() - missionStart) / 1000.0,
          end: (new Date(c.endTime).getTime() - missionStart) / 1000.0
        }
      });
      return sClips;
    });
  }
  else
    return null;
}


/**
 * 
 * @param {Object} mission 
 * @param {Object} sensors 
 * @returns parsed time for mission start
 */
const getMissionStartTimeStamp = mission => {
  if (mission) {
    return new Date(mission.startTime).getTime();
  }
  else
    return null;
}

const setMasterSensorTimeMinInterval = 1.0; // Polling interval in seconds


/**
 * MissionPlayerGrid renders the mission related sensors
 * 
 * TODO: Add timeline
 * @param  {} props
 */
function MissionPlayerGrid(props) {

  const { server } = useContext(serverContext);
  const { app, dispatchApp } = useContext(appContext);
  const [klvData, setKlvData] = useState({});
  const [klvWndLocation, setKlvWndLocation] = useState(() => getLastLocation('klv'));
  const [mapWndLocation, setMapWndLocation] = useState(() => getLastLocation('floatingMap'));
  const [playerWidth, setPlayerWidth] = useState(640);
  const [containerWidth, setContainerWidth] = useState(840);
  const [sensorsHeights, setSensorsHeights] = useState({});
  const [sensorsAspectRatios, setSensorsAspectRatios] = useState({});
  const [mapAspectRatio, setMapAspectRatio] = useState(16 / 9);
  const [mapProps, setMapProps] = useState(() => getLastMapProps());
  const [mapPlatforms, setMapPlatforms] = useState([]);
  const [mapDraggable, setMapDraggable] = useState(true);
  const [videoQueryChart, setVideoQueryChart] = useState(null);
  const [currentMapControlsMode, setCurrentMapControlsMode] = useState(props.selectMapControl ? props.selectMapControl : MapControlsMode.None);
  const [currentAoiControlsMode, setCurrentAoiControlsMode] = useState(mapProps.aoiControlsMode);
  const [liveSensorsMode, setLiveSensorsMode] = useState({});
  const [placeholderHeight, setPlaceholderHeight] = useState(playerWidth * 16 / 9);

  const gridMapControl = useRef({});
  const floatingMapControl = useRef({});
  const masterSensor = useRef(null);
  const [masterSensorName, setMasterSensorName] = useState(null);
  const masterSensorIndex = useRef(0);
  const [masterSensorTime, setMasterSensorTime] = useState(null);

  const { mission } = useMission(server.serverHost, props.mission, server.token, props.missionUpdateTime);
  const { sensors: mSensors } = useSensors(server.serverHost, props.mission, server.token, props.missionUpdateTime);

  const lastMasterKlvTimestamp = useRef(null);
  const players = useMemo(() => props.sensors.map(p => createRef()), [props.sensors]);
  const missionClipPositions = useMemo(() => getMissionClipsPosition(mission, mSensors), [mission, mSensors]);
  const missionStartTimestamp = useMemo(() => getMissionStartTimeStamp(mission), [mission]);
  const timelinePos = useSyncTimeline(missionStartTimestamp, missionClipPositions, players, masterSensorIndex.current, masterSensorTime, 0.5);

  const klvViewerOpen = props.controls && props.controls.includes('klv');
  const mapViewerOpen = props.controls && props.controls.includes('floatingMap');
  const gridMapOpen = props.controls && props.controls.includes('gridMap');
  const bookmarksChartOpen = props.controls && props.controls.includes('bookmarks');
  const exportVideotOpen = props.controls && props.controls.includes('exportVideo');
  const [createBookmarkOpen, setCreateBookmarkOpen] = useState(false);
  const [bookmarksDlgOpen, setBookmarksDlgOpen] = useState(false);
  const [bookmarksUpdateTime, setBookmarksUpdateTime] = useState(Date.now());

  useEffect(() => {

    const setWidth = () => {
      const curPlayerWidth = calculatePlayerWidth(gridMapOpen, containerWidth, props.sensors.length);
      setPlayerWidth(curPlayerWidth);
      setPlaceholderHeight(curPlayerWidth * 16 / 9);
    }

    setWidth();

  }, [props.sensors.length, containerWidth, gridMapOpen])


  useEffect(() => {

    const curPlayerWidth = calculatePlayerWidth(gridMapOpen, containerWidth, props.sensors.length);

    let heights = {};
    let aspectRatios = {};
    let _liveSensorsMode = {};

    // Add VOD sensors
    if (props.mode === 'vod' && props.sensors.length > 0) {
      props.sensors.forEach(s => {
        const aspectRatio = s.videoInfo ? s.videoInfo.width / s.videoInfo.height : 16 / 9;
        heights[`${s.m}/${s.s}`] = curPlayerWidth / aspectRatio;
        aspectRatios[`${s.m}/${s.s}`] = aspectRatio;
      })
      masterSensor.current = `${props.sensors[0].m}/${props.sensors[0].s}`;
      setMasterSensorName(`${props.sensors[0].m}/${props.sensors[0].s}`);
    }

    // Add Live sensors  
    if (props.mode === 'live' && props.sensors.length > 0) {
      props.sensors.forEach(s => {
        const videoInfo = s.streamInfo ? s.streamInfo.streams.filter(s => s.codec_type === 'video') : null;
        const aspectRatio = Array.isArray(videoInfo) ? videoInfo[0].width / videoInfo[0].height : 16 / 9;
        heights[`${s.parentPlatformName}/${s.name}`] = placeholderHeight;
        aspectRatios[`${s.parentPlatformName}/${s.name}`] = aspectRatio;

        const liveModeType = liveSensorsMode[`${s.parentPlatformName}/${s.name}`] || getLiveMode(props);
        _liveSensorsMode[`${s.parentPlatformName}/${s.name}`] = liveModeType;
      })
      masterSensor.current = `${props.sensors[0].parentPlatformName}/${props.sensors[0].sensor}`;
      setMasterSensorName(`${props.sensors[0].parentPlatformName}/${props.sensors[0].sensor}`);
    }

    setLiveSensorsMode(_liveSensorsMode);
    setSensorsHeights(heights);
    setSensorsAspectRatios(aspectRatios);

    // TODO: Tmp. It should be for sensor per line
    let minAspectRatio = 16 / 9;
    for (let [value] of Object.entries(aspectRatios)) {
      if (value < minAspectRatio)
        minAspectRatio = value;
    }

    setMapAspectRatio(minAspectRatio);

  }, [props.sensors, props.sensors.length, props.mode, containerWidth, gridMapOpen, placeholderHeight])

  const handleResolutionDetection = (source, width, height) => {
    //console.log(`${source}: ${width}x${height}`);
    sensorsAspectRatios[`${source}`] = width / height;
    setSensorsAspectRatios(sensorsAspectRatios);
    //console.log(sensorsAspectRatios);
  }

  const handleDimensionsChange = (source, width, height) => {
    //console.log(`${source}: ${width}x${height}`);
    sensorsHeights[`${source}`] = width / sensorsAspectRatios[`${source}`];
    setSensorsHeights(sensorsHeights);
    //console.log(sensorsHeights);
  }

  /**
   * 
   * @param {String} type 
   * @param {Object} location 
   */
  const handleLocationChange = (type, location) => {
    saveLastLocation(type, location);
    switch (type) {
      case 'klv':
        setKlvWndLocation(location);
        break;
      case 'gridMap':
        break;
      case 'floatingMap':
        setMapWndLocation(location);
        break;
      default:
        break;
    }
  }

  /**
   * 
   * @param {String} source 
   * @param {String} platformType 
   * @param {Object} data 
   */
  const handleKlvDataReceived = (source, i, platformType, data) => {

    if (data) {

      const t = data['2'];

      if (players[i].current) {
        players[i].current.klvTime = t;
      }

      if (source === masterSensor.current) {
        setKlvData(data);

        if (masterSensorIndex.current === i) {

          if (lastMasterKlvTimestamp.current == null) {
            lastMasterKlvTimestamp.current = t;
          }
          else
            if (Math.abs(t - lastMasterKlvTimestamp.current) / 1000000 >= setMasterSensorTimeMinInterval) {
              lastMasterKlvTimestamp.current = t;
              // console.log(`setMasterSensorTime - ${t}`);
              setMasterSensorTime(t);
            }
        }

        // update map if platform position arrived in the packet
        if (data['13'] !== undefined && data['14'] !== undefined) {
          setMapPlatforms([{
            name: source,
            platformType: platformType ? platformType.toLowerCase() : 'plane',
            klvs: data
          }])
        }
      }
    }
  }


  const handleTimelineCrosshairClick = tlPos => {

    const masterPlayer = players[masterSensorIndex.current].current;

    if (!Array.isArray(missionClipPositions) || missionClipPositions.length === 0 || missionClipPositions[0].length === 0) {   // For backward compatibility

      masterPlayer.currentTime(tlPos);
    }
    else {
      const { clipIndex, clipPos, outOfRange } = timelineToClipPos(missionClipPositions, masterSensorIndex.current, tlPos);
      //console.log(`timelinePos - ${tlPos} clipIndex - ${clipIndex} clipPos - ${clipPos} outOfRange - ${outOfRange}`);

      if (!outOfRange) {
        masterPlayer.playlist.currentItem(clipIndex);
        masterPlayer.currentTime(clipPos);
      }
      else {
        setTimeout(() => {
          const { nextClipIndex, outOfRange } = getNextClipIndex(missionClipPositions, masterSensorIndex.current, tlPos);
          if (!outOfRange) {
            masterPlayer.playlist.currentItem(nextClipIndex);
            masterPlayer.currentTime(0);
          }
        }, 100);
      }
    }
  }

  const handlePlayerClick = (event, name, index) => {

    console.log(`set masterSensor - ${name}. Index - ${index}`);
    masterSensor.current = name;
    masterSensorIndex.current = index;
    setMasterSensorName(name);

    event.stopPropagation();
  }

  const handleMapClose = mapProps => {
    setMapProps(mapProps);
    saveLastMapProps(mapProps);
  }

  const handleMapMouseOver = () => {
    setMapDraggable(false);
  }

  const handleMapMouseLeave = () => {
    setMapDraggable(true);
  }

  const getCombinedSensors = () => {
    // Combine vod and live sensors in one array
    const combinedArray = [];

    if (props.mode === 'vod') {
      return props.sensors;
    }
    else {
      const liveSensorsTr = props.sensors.map(ls => {
        return {
          m: ls.mission,
          s: ls.sensor
        }
      })
      combinedArray.push(...liveSensorsTr);
    }

    return combinedArray;
  }

  const handleShowHeatmapClick = () => {

    return new Promise(async (resolve, reject) => {

      const combinedArray = getCombinedSensors();

      if (combinedArray.length > 0) {

        let hPoints = [];
        for (const s of combinedArray) {
          try {
            const hP = await fetchFromServer(`sensors/framecenterpoints/${s.m}/${s.s}`, server)
            hPoints = [...hPoints, ...hP.data];
          } catch (error) {

          }
        }

        resolve(hPoints);
      }
      else {
        const errorMessage = "No sensors selected."
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: errorMessage, severity: 'error' } });
        reject(new Error(errorMessage))
      }
    })
  }

  const handleShowAreaClick = () => {

    return new Promise(async (resolve, reject) => {

      const combinedArray = getCombinedSensors();

      if (combinedArray.length > 0) {
        let mArea;

        try {
          const res = await fetchFromServer(`missions/area/${combinedArray[0].m}`, server);
          mArea = res.data;
        } catch (error) {
          const errorMessage = `Fetching area error - ${error.response && error.response.data ? error.response.data : error.message}`;
          dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: errorMessage, severity: 'error' } });
          reject(new Error(errorMessage));
        }

        resolve(mArea);


        // const combinedArray = getCombinedSensors();

        // if (combinedArray.length > 0) {
        //   let mArea;
        //   for (const s of combinedArray) {
        //     try {
        //       const sArea = await fetchFromServer(`sensors/area/${s.m}/${s.s}`, server);
        //       //TODO:
        //       mArea = sArea.data;
        //     } catch (error) {
        //       const errorMessage = `Fetching area error - ${error.response && error.response.data ? error.response.data : error.message}`;
        //       dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: errorMessage, severity: 'error' } });
        //       reject(new Error(errorMessage));
        //     }
        //   }
        //   resolve(mArea);
      } else {
        const errorMessage = 'No mission selected.';
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: errorMessage, severity: 'error' } });
        reject(new Error(errorMessage))
      }
    })
  }

  const handleShowPathClick = () => {

    return new Promise(async (resolve, reject) => {

      const combinedArray = getCombinedSensors();

      if (combinedArray.length > 0) {
        let mPath;
        for (const s of combinedArray) {
          try {
            const sPath = await fetchFromServer(`sensors/path/${s.m}/${s.s}`, server);
            //TODO:
            mPath = sPath.data;
          } catch (error) {
            const errorMessage = `Error getting path - ${error.response && error.response.data ? error.response.data : error.message}`;
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: errorMessage, severity: 'error' } });
            return reject(new Error(errorMessage))
          }
        }
        resolve(mPath);
      } else {
        const errorMessage = "No sensors selected."
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: errorMessage, severity: 'error' } });
        reject(new Error(errorMessage))
      }
    })
  }

  const handleMapControlsModeChange = async mode => {

    switch (currentMapControlsMode) {

      case MapControlsMode.None:
        break;

      case MapControlsMode.QueryMission:
        setVideoQueryChart(null);
        break;

      case MapControlsMode.FilterMissions:
        // Clear geo filter
        const filter = { ...app.missionsFilter, aoiMode: undefined, geometry: undefined };

        if (props.onApplyQueryFilter)
          props.onApplyQueryFilter(filter);
        break;

      case MapControlsMode.DrawAnnotations:
        break;

      default:
        break;
    }

    // Update the state
    setCurrentMapControlsMode(mode);
  }

  const handleAoiControlsModeChange = async mode => {
    setCurrentAoiControlsMode(mode);
  }

  const queryServer = (mapControlsMode, geoJson) => {
    return new Promise(async (resolve, reject) => {

      try {
        switch (mapControlsMode) {

          case MapControlsMode.QueryMission:
            if (masterSensor.current) {
              setVideoQueryChart({});
              const resVideoList = await getFromServer(`sensors/videolist/${masterSensor.current}`, { geometry: geoJson, aoiMode: currentAoiControlsMode, start: undefined, end: undefined }, server);
              setVideoQueryChart({ id: masterSensor.current, data: resVideoList.data });
            }
            else
              dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Please select mission to query.` } });
            resolve();
            break;

          case MapControlsMode.FilterMissions:
            const filter = { ...app.missionsFilter, geometry: geoJson, aoiMode: currentAoiControlsMode };
            if (props.onApplyQueryFilter)
              props.onApplyQueryFilter(filter);
            resolve();
            break;

          case MapControlsMode.DrawAnnotations:
            resolve();
            break;

          default:
            resolve();
            break;
        }

      } catch (error) {
        resolve();
        dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `${error.message}. ${error.response.data}` } });
      }
    })
  }

  const handleDrawAction = (mapControlsMode, eventType, geoJson) => {

    return new Promise(async (resolve, reject) => {

      try {
        switch (eventType) {

          case 'draw:created':
          case 'draw:edited':
            await queryServer(mapControlsMode, geoJson);
            break;

          case 'draw:deleted':
            setVideoQueryChart(null);
            break;

          default:
            break;
        }

      } catch (error) {

      }
      resolve();
    })
  }


  const seekTo = (i, position) => {
    const player = players[i].current;
    if (player) {
      const seekable = player.seekable();
      if (seekable && seekable.length) {

        if (position < 0) { // if we're asked to seek a negative value, seek to the beginning and pause
          player.currentTime(0);
          player.pause();
          return;
        }

        const end = seekable.end(0);
        if (position > end) { // if we're asked to seek beyond the range, seek to the end
          player.currentTime(end);
          return;
        }

        player.currentTime(position);
        player.play();
      }
    }
  }

  const handleVideoCoverageChartClick = (i, pos) => {

    const { offset } = pos;
    seekTo(i, offset);
  }

  const handleBookmarkChartClick = (i, position) => {
    seekTo(i, position);
  }

  const handleBookmarkTimelineClick = (sensorName, bm) => {

    const i = props.sensors.findIndex(s => s.s === sensorName);

    seekTo(i, bm.position);
  }


  const handleLiveModeControlChange = (id, mode) => {

    liveSensorsMode[id] = mode;
    setLiveSensorsMode({ ...liveSensorsMode });
  }


  const handleLiveModeControlError = error => {
    dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: error, severity: 'error' } });
  }

  const handleAddBookmark = () => {
    setCreateBookmarkOpen(true);
  }

  const handleDeleteBookmarks = async bookmarks => {
    if (masterSensorIndex.current !== undefined) {

      if (Array.isArray(bookmarks) && bookmarks.length > 0) {

        try {
          //const id = masterSensor.current.split('/');   
          const bmIds = bookmarks.map(b => b._id);

          const ret = await callServer('delete', 'bookmarks', { bookmarks: bmIds }, server);

          if (ret.status === 200)
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Bookmark(s) ${bookmarks.map(b => b.title).join(', ')} deleted.` } });

          setTimeout(() => {
            setBookmarksUpdateTime(Date.now());
          }, 500);

        } catch (error) {
          dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error deleting bookmarks ${error.message} - ${error.request ? error.request.statusText : ''}`, severity: 'error' } });
        }
      }
    }
    else
      dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: 'Master sensor is not selected', severity: 'error' } });
  }

  const handleCloseCreateBookmark = () => {
    setCreateBookmarkOpen(false);
  }

  const handleCreateBookmark = async bm => {

    try {
      if (masterSensorIndex.current !== undefined) {
        const id = masterSensor.current.split('/');

        const newBookmark = {
          missionIdOrName: id[0],
          sensorIdOrName: id[1],
          ...bm
        }

        const ret = await postToServer('bookmarks', newBookmark, server);
        if (ret.status === 200)
          dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Bookmark ${bm.title} added.` } });

        setTimeout(() => {
          setBookmarksUpdateTime(Date.now());
        }, 200);

      }
      else
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: 'Master sensor is not selected', severity: 'error' } });
    } catch (error) {
      dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `${error.message}. ${error?.response?.data}`, severity: 'error' } });
    }

    setCreateBookmarkOpen(false);
  }

  const handleEditBookmarks = bookmarks => {
    setBookmarksDlgOpen(!bookmarksDlgOpen);
  }

  const handleCloseEditBookmarks = () => {
    setBookmarksDlgOpen(false);
  }

  const handleContainerResize = (width, height) => {
    setContainerWidth(width);
  }

  const masterSlavePlayerWrapper = id => {
    return masterSensor.current === id ? classes.masterPlayerWrapper : classes.playerWrapper;
  }

  const handleExportVideo = (mi, si, vid) => {

    if (server.userRole) {
      const userRoleAdmin = server.userRole === 'superAdmin' || server.userRole === 'admin';
      if (!userRoleAdmin) {
        const message = `You don't have admin rights to download the video.`;
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: message, severity: 'warning' } });
        return;
      }
    }

    try {
      const clipName = `${mi}-${si}-${vid.markIn}-${vid.markOut}.ts`;
      const message = `Exporting clip ${clipName}...`;
      dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: message } });
      downloadFile(server, `sensors/exportVideo/${mi}/${si}?markIn=${vid.markIn}&markOut=${vid.markOut}`, clipName);
    } catch (error) {
      console.log(error);
    }
  }

  const classes = useStyles();
  return (
    <ReactResizeDetector handleHeight={false} onResize={handleContainerResize}>
      <Grid key='sensors' container className={classes.root} spacing={2}>
        <Grid container justify="center" spacing={1}>

          {props.mode === 'vod' && props.sensors.map((s, i) => (
            <Grid key={`${s.m}/${s.s}`} item onClick={evt => handlePlayerClick(evt, `${s.m}/${s.s}`, i)} >
              <Paper elevation={2} className={masterSlavePlayerWrapper(`${s.m}/${s.s}`)} >
                <Box height={sensorsHeights[`${s.m}/${s.s}`]} width={playerWidth} display="inline-block" >
                  <SensorPlayerToolbar
                    live={false}
                    missionIdOrName={s.m}
                    sensorIdOrName={s.s}
                    master={`${s.m}/${s.s}` === masterSensorName}
                  />
                  <SensorPlayer
                    ref={players[i]}
                    mode={'highQualityDvr'}
                    aspectRatioChangeBehaviour={'keepWidth'}
                    missionIdOrName={s.m}
                    sensorIdOrName={s.s}
                    serverUrl={server.serverHost}
                    useReverseProxy={server.useReverseProxy}
                    token={server.token}
                    clientId={server.clientId}
                    videoDir={"videos"}
                    playbackDataDelay={server.playbackDataDelay}
                    username={server.username}
                    password={server.password}
                    browserInfo={server.browserInfo}
                    onDataReceive={data => handleKlvDataReceived(`${s.m}/${s.s}`, i, s.platformType, data)}
                    onResolutionDetect={(width, height) => handleResolutionDetection(`${s.m}/${s.s}`, width, height)}
                    onDimensionsChange={(width, height) => handleDimensionsChange(`${s.m}/${s.s}`, width, height)}
                  />
                </Box>
                {
                  videoQueryChart && videoQueryChart.id === `${s.m}/${s.s}` &&
                  <div className={classes.playerBottomControls} >
                    <Box className={classes.videoCoverageChart}>
                      <VideoCoverageChart data={videoQueryChart.data} onClick={pos => handleVideoCoverageChartClick(i, pos)} />
                    </Box>
                  </div>
                }
                {bookmarksChartOpen &&
                  <div className={classes.playerBottomControls} >
                    <StBookmarksTimeline serverUrl={server.serverHost} token={server.token} missionIdOrName={s.m} sensorIdOrName={s.s} bookmarksUpdateTime={bookmarksUpdateTime} onBookmarkClick={bm => handleBookmarkChartClick(i, bm.position)} onBookmarkAddClick={handleAddBookmark} onBookmarkListClick={handleEditBookmarks} />
                  </div>
                }
                 {exportVideotOpen &&
                  <div className={classes.playerBottomControls} >
                    <StCutControl serverUrl={server.serverHost} serverToken={server.token} missionIdOrName={s.m} sensorIdOrName={s.s} curPos={timelinePos} onExport={vid => handleExportVideo(s.m, s.s, vid)}/>
                  </div>
                }
              </Paper>
            </Grid>
          ))}

          {props.mode === 'live' && props.sensors.map((s, i) => (
            <Grid key={`${s.parentPlatformName}/${s.name}`} item onClick={evt => handlePlayerClick(evt, `${s.parentPlatformName}/${s.name}`, i)} >
              <Paper elevation={2} className={masterSlavePlayerWrapper(`${s.parentPlatformName}/${s.name}`)}>
                <Box height={sensorsAspectRatios[`${s.parentPlatformName}/${s.name}`] ? playerWidth / sensorsAspectRatios[`${s.parentPlatformName}/${s.name}`] + 24 : placeholderHeight} width={playerWidth} display="inline-block" >
                  <SensorPlayerToolbar
                    serverUrl={server.serverHost}
                    username={server.username}
                    password={server.password}
                    token={server.token}
                    useLiveLowLatency={server.useLiveLowLatency}
                    useWebRtcVideo={server.useWebRtcVideo}
                    useLiveRtspHls={server.useLiveRtspHls}
                    master={`${s.parentPlatformName}/${s.name}` === masterSensorName}
                    live={true}
                    allowLiveHighQualityModeWithoutQuery={s.live && s.recorderInfo && s.recorderInfo.info && s.recorderInfo.info.totalSegments > 2}
                    missionIdOrName={s.m}
                    sensorIdOrName={s.s}
                    platformName={s.parentPlatformName}
                    sensorName={s.name}
                    mode={liveSensorsMode[`${s.parentPlatformName}/${s.name}`] ? liveSensorsMode[`${s.parentPlatformName}/${s.name}`] : null}
                    onLiveControlModeChange={handleLiveModeControlChange}
                    onError={handleLiveModeControlError}
                  />
                  <SensorPlayer
                    ref={players[i]}
                    height={sensorsAspectRatios[`${s.parentPlatformName}/${s.name}`] ? playerWidth / sensorsAspectRatios[`${s.parentPlatformName}/${s.name}`] : placeholderHeight}
                    width={playerWidth}
                    mode={liveSensorsMode[`${s.parentPlatformName}/${s.name}`] ? liveSensorsMode[`${s.parentPlatformName}/${s.name}`] : null}
                    webRtcService={server.webRtcService}
                    showControls={true}
                    serverUrl={server.serverHost}
                    token={server.token}
                    clientId={server.clientId}
                    clientIp={server.clientIp}
                    videoDir={"videos"}
                    username={server.username}
                    password={server.password}
                    wsVideoStreamPort={s.config?.wsVideoStreamPort}
                    useReverseProxy={server.useReverseProxy}
                    platformName={s.parentPlatformName}
                    sensorName={s.name}
                    missionIdOrName={s.mission} // For VOD
                    sensorIdOrName={s.name}  // For VOD                                  
                    browserInfo={server.browserInfo}
                    onDataReceive={data => handleKlvDataReceived(`${s.parentPlatformName}/${s.name}`, i, s.platformType, data)}
                    onResolutionDetect={(width, height) => handleResolutionDetection(`${s.parentPlatformName}/${s.name}`, width, height)}
                    onDimensionsChange={(width, height) => handleDimensionsChange(`${s.parentPlatformName}/${s.name}`, width, height)}
                  />
                </Box>
                {
                  videoQueryChart && videoQueryChart.id === `${s.m}/${s.s}` &&
                  <Box className={classes.videoCoverageChart}>
                    <VideoCoverageChart data={videoQueryChart.data} onClick={pos => handleVideoCoverageChartClick(i, pos)} />
                  </Box>
                }
              </Paper>
            </Grid>
          ))}

          {gridMapOpen &&
            <Grid key={`gridMap`} item >
              <Paper elevation={2} >
                <UavMap width={playerWidth} height={playerWidth / mapAspectRatio + 28}
                  ref={m => gridMapControl.current = m}
                  platforms={mapPlatforms} symbols={[]} milSymbols={[]}
                  selectedChannel={0}
                  showVmti={false} showTrail={true} trailDuration={20}
                  mapCenterMode={mapProps.mapCenterMode}
                  mapControlsMode={currentMapControlsMode}
                  aoiControlsMode={mapProps.aoiControlsMode}
                  customMap={null}
                  lastMapCenter={mapProps.center}
                  lastMapZoom={mapProps.zoom}
                  dragging={true}
                  onMapControlsModeChange={handleMapControlsModeChange}
                  onAoiControlsModeChange={handleAoiControlsModeChange}
                  onShowHeatmapClick={handleShowHeatmapClick}
                  onShowAreaClick={handleShowAreaClick}
                  onShowPathClick={handleShowPathClick}
                  onDrawAction={handleDrawAction}
                  onMouseOver={handleMapMouseOver}
                  onMouseLeave={handleMapMouseLeave}
                  onClose={handleMapClose}
                />
              </Paper>
            </Grid>
          }
          {
            bookmarksDlgOpen &&
            <BookmarksDlg currentSensor={masterSensor.current} bookmarksUpdateTime={bookmarksUpdateTime} onDeleteBookmarks={handleDeleteBookmarks} onClose={handleCloseEditBookmarks} />
          }
        </Grid>
        <Grid item xs={12} md={12} lg={12} className={classes.timeline} >
          {
            props.mode !== 'live' && props.timelineOpen &&
            <CurrentMissionTimeline missionName={props.mission} curPos={timelinePos} missionUpdateTime={props.missionUpdateTime} bookmarksUpdateTime={bookmarksUpdateTime} onTimelineCrosshairClick={handleTimelineCrosshairClick} onBookmarkClick={handleBookmarkTimelineClick} />
          }
        </Grid>
      </Grid>

      {klvViewerOpen && (
        <FloatingWnd location={klvWndLocation} draggable={true} onLocationChange={location => handleLocationChange('klv', location)}>
          <div className='telemetryContainer' style={{ width: '100%', height: '100%', overflowY: 'auto', background: 'white' }}>
            <KlvView data={klvData} title={masterSensor.current} />
          </div>

        </FloatingWnd>
      )}

      {mapViewerOpen && (
        <FloatingWnd location={mapWndLocation} onLocationChange={location => handleLocationChange('floatingMap', location)} draggable={mapDraggable}  >
          <div id="container" >
            <UavMap width={mapWndLocation.width} height={mapWndLocation.height}
              ref={m => floatingMapControl.current = m}
              platforms={mapPlatforms} symbols={[]} milSymbols={[]}
              selectedChannel={0}
              showVmti={false} showTrail={false} trailDuration={20}
              mapCenterMode={mapProps.mapCenterMode}
              mapControlsMode={currentMapControlsMode}
              aoiControlsMode={mapProps.aoiControlsMode}
              customMap={null}
              lastMapCenter={mapProps.center}
              lastMapZoom={mapProps.zoom}
              dragging={true}
              onMapControlsModeChange={handleMapControlsModeChange}
              onAoiControlsModeChange={handleAoiControlsModeChange}
              onShowHeatmapClick={handleShowHeatmapClick}
              onShowAreaClick={handleShowAreaClick}
              onShowPathClick={handleShowPathClick}
              onDrawAction={handleDrawAction}
              onMouseOver={handleMapMouseOver}
              onMouseLeave={handleMapMouseLeave}
              onClose={handleMapClose}
            />
          </div>
        </FloatingWnd>
      )}
      {
        createBookmarkOpen && <CreateBookmarkDlg player={players[masterSensorIndex.current].current} timestamp={klvData ? moment.unix(klvData['2'] / 1000 / 1000).format('DD MMM YYYY, HH:mm:ss.SSSZZ') : undefined} onCreateBookmark={handleCreateBookmark} onClose={handleCloseCreateBookmark} />
      }
    </ReactResizeDetector>
  );
}

export default MissionPlayerGrid;