import React, { useState, useEffect } from 'react';
import { MuiThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: blue[600],
    }
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const getSteps = editMode => {
  const steps = ['General', 'Presentation', 'Sensors'];
  steps.push(editMode ? 'Update' : 'Create');
  return steps;
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Set General settings';
    case 1:
      return 'Set presentation';
    case 2:
      return 'Create sensors and import assets';
    case 3:
      return 'Process assets and create mission';
    default:
      return 'Unknown step';
  }
}



/**
 * CreateMissionStepper
 * @param  {} props
 */
export default function CreateMissionStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  // const [completed, setCompleted] = React.useState({});
  const steps = getSteps(props.editMode);


  const isStepOptional = step => {
    return step === 1;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };


  const handleNext = () => {

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // const handleStep = step => () => {
  //   setActiveStep(step);
  // };

  useEffect(() => {
    if (props.onStep)
      props.onStep(activeStep);
  }, [props, activeStep]);


  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ?
          <React.Fragment>
            <Typography className={classes.instructions}>
              All steps completed. Press OK to submit.
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </React.Fragment>
          :
          <React.Fragment>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <Button size="small" disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
              Back
              </Button>
            {isStepOptional(activeStep) && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSkip}
                className={classes.button}
              >
                Skip
              </Button>
            )}
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>

          </React.Fragment>
        }
      </MuiThemeProvider>
    </div>

  );
}
