import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { BackupsTable } from 'stserver-frontend-comp';
import { serverContext, appContext, callServer } from '../App.js';

export default function ServerBackupsTable() {

  const { server } = useContext(serverContext);
  const { dispatchApp } = useContext(appContext);
  const [updated, setUpdated] = useState(Date.now());

  const handleDeleteBackups = async backups => {
    try {

      const ret = await callServer('delete', `backups?backups=${backups}`, undefined, server);

      if (ret.status === 200) {
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Conference room  ${backups} deleted.` } });
      }
      else
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error deleting room ${backups}: ${ret.response.data}.`, severity: 'error' } });

    } catch (error) {
      dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error deleting backups: ${error.message}. ${error.response.data}`, severity: 'error' } });
    }

    setUpdated(Date.now());
  }

  return (
    <Grid container spacing={1}  >
      <Grid item xs={12} >
        <BackupsTable serverUrl={server.serverHost} username={server.username} password={server.password} onDeleteBackups={handleDeleteBackups} update={updated} />
      </Grid>
    </Grid>
  );
}