import React, { useState, useContext, forwardRef } from 'react';
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import { serverContext, translateUserMessages } from '../App';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';



const tableIcons = {
  AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#47A3E8',
    },
  }
});


const useStyles = makeStyles(theme => ({
  message: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(4),
    color: 'grey',
  }
}));


/**
 * table columns
 *  @param  {} cb
 */
const columns = [
  { title: 'Subject', field: 'title', tooltip: 'Message subject' },
  { title: 'From', field: 'sender', tooltip: 'Message sender', width: '15%', render: rowData => { return (rowData.sender !== undefined ? `${rowData.sender.firstName}  ${rowData.sender.lastName}` : 'N/A') } },
  { title: 'Email', field: 'sender', tooltip: `Sender's email`, width: '15%', render: rowData => { return (rowData.sender !== undefined ? `${rowData.sender.email}` : 'N/A') } },
  { title: 'Date', field: 'timestamp', tooltip: 'Message date', render: rowData => { return moment(rowData.timestamp).format("MMM Do YY, hh:mm:ss") } },
];



const UserMessageTable = props => {

  const { server } = useContext(serverContext);
  const [selectedRow, setSelectedRow] = useState(null);

  const actions = [];

  const classes = useStyles();

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          data={Array.isArray(server.usermessages) ? translateUserMessages(server.usermessages) : []}
          title={props.title || 'Messages'}
          detailPanel={rowData => {
            return (
              <ReactMarkdown children={rowData.message} className={classes.message} />
            )
          }}
          onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow))}
          options={{
            selection: true,
            rowStyle: rowData => ({
              backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EFFBFF' : '#FFF',
            }),
            headerStyle: {
              backgroundColor: '#EEE',
            }
          }}
          actions={actions}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
}



export default UserMessageTable;
