import update from 'immutability-helper';


/**
 * appReducer
 * @param  {} app
 * @param  {} action
 */
const appReducer = (app, action) => {
    switch (action.type) {
  
      case 'SHOW_ALERT':
        return update(app, { alertMessage: { $set: { open: true, title: action.payload.title, message: action.payload.message, keepOpened: action.payload.keepOpened } } });
  
      case 'HIDE_ALERT':
        return update(app, { alertMessage: { $set: { open: false, title: '', message: '' } } });
  
      case 'SHOW_SNACKBAR_MESSAGE':
        return update(app, { snackbarMessage: { $set: { open: action.payload.open !== undefined ? action.payload.open : true, message: action.payload.message, severity: action.payload.severity, variant: action.payload.variant === undefined ? action.payload.variant : 'filled' } } });
  
      case 'UPDATE_MISSIONS_FILTER':
        return update(app, { missionsFilter: { $set: action.payload } });    

     case 'SET_TIMELINE_START_TIME':
       return update(app, { timelineStartTime: { $set: action.payload } });      

      // case 'SET_TIMELINE_TIME':  
      // const pos = (action.payload - app.timelineStartTime) / 1000.0;      
      // return pos == app.timelinePos ? app : update(app, { timelineTime: { $set: action.payload }, timelinePos: { $set: pos } });                 

      default:
        return app;
    }
  };


export default appReducer;
  