import 'date-fns';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import blue from "@material-ui/core/colors/blue";

import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';


const defaultMaterialTheme = createTheme({
    palette: {
        primary: blue,
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    picker: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 240,
    },
}));

const DateTimeRange = props => {

    const handleFromChange = date => {
        if (props.onTimeSelected) {
            const end = props.selectTime ? props.selectTime.end : undefined;
            props.onTimeSelected({ start: date ? date.toISOString() : undefined, end: end });
        }
    };

    const handleToChange = date => {
        if (props.onTimeSelected) {
            const start = props.selectTime ? props.selectTime : undefined;
            props.onTimeSelected({ start: start, end: date ? date.toISOString() : undefined });
        }
    };

    const classes = useStyles();
    const { selectTime } = props;

    const start = selectTime && selectTime.start ? selectTime.start : null;
    const end = selectTime && selectTime.end ? selectTime.end : null

    return (
        <div className={classes.container}>
            <ThemeProvider theme={defaultMaterialTheme} className={classes.container}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        className={classes.container}
                        label="From"
                        ampm={false}
                        disableFuture
                        autoOk={false}
                        clearable
                        showTodayButton
                        format="MMM D YYYY, h:mm"
                        value={start}
                        onChange={handleFromChange} />
                    <DateTimePicker
                        className={classes.container}
                        label="To"
                        ampm={false}
                        autoOk={false}
                        clearable
                        showTodayButton
                        disableFuture
                        format="MMM D YYYY, h:mm"
                        value={end}
                        onChange={handleToChange} />
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </div>
    );
}

export default DateTimeRange;