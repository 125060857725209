import React, { useContext } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import PeopleIcon from '@material-ui/icons/People';
import { withStyles } from "@material-ui/core/styles";
import { serverContext } from '../App';

const styles = theme => ({
    margin: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    customBadge: {
        backgroundColor: props => props.badgeColor || "#4caf50",
        color: "white"
    },
    customIcon: {
        color: props => props.iconColor
    }
});

const OnlineUsersCount = props => {
    const { classes } = props;
    const { server } = useContext(serverContext);

    return (
        <div className={classes.margin}>
            <Tooltip title="Users online">
                <Badge badgeContent={server.onlineUsers} classes={{ badge: classes.customBadge }}>
                    <PeopleIcon className={classes.customIcon} />
                </Badge>
            </Tooltip>
        </div>
    );
}

export default withStyles(styles)(OnlineUsersCount);