
/**
 * missionsReducer
 * @param  {} missions
 * @param  {} action
 */
const missionsReducer = (missions, action) => {
  switch (action.type) {
    case 'SET_MISSIONS':
      return [...action.payload];

    case 'UPDATE_MISSION': {
      const message = action.payload;
      let updatedMissions;

      if (message) {
        updatedMissions = missions.map(ms => {
          let msClone = { ...ms };
         
          if (msClone.name === message.mission) {
            if(message.state)
              msClone.state = message.state;
 
              msClone.update = {
                type: message.type,
                value: message.value
              }
          }
          return msClone;
        })
        return updatedMissions;
      }
      else
        return missions;
    }
 
    default:
      return missions;
  }
};



export default missionsReducer;
