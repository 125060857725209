import React from 'react';
import { MuiThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';


const theme = createTheme({

});

const useStyles = makeStyles(theme => ({

    badge: {
        paddingTop: 10,
        paddingRight: 24
    }
}));


// Missions are provided in a form suitable for the material table, so we need to count the ones with no parentId
const countMissionsOrPlatforms = mp => {
    return Array.isArray(mp) && mp.length > 0 ? mp.reduce((total, m) => total + (m.parentId !== undefined ? 0 : 1), 0) : 0;
}

const MissionOrPlatformCounter = props => {

    const { data } = props;
    const classes = useStyles();

    return (
        <div className={classes.badge}>
            <MuiThemeProvider theme={theme}>
                <Tooltip title="Total missions">
                    <Badge badgeContent={countMissionsOrPlatforms(data)} color="primary">
                        <Typography noWrap variant="overline" display="block">{props.title}</Typography>
                    </Badge>
                </Tooltip>
            </MuiThemeProvider>
        </div>
    )
}

export default MissionOrPlatformCounter;