import React, { useState, useEffect, useContext, forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Slide } from '@material-ui/core';
import Title from './Title';
import ShowState from './showState';
import moment from 'moment';
import { serverContext, appContext, fetchFromServer, postToServer } from '../App';
import { StServerVersion } from 'stserver-frontend-comp';
import { green, red } from '@material-ui/core/colors';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useServerGet } from '../customEffects';


const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" {...props} ref={ref} />;
})

const ReverseProxyStatus = props => {
    return props.useReverseProxy ?
        <Tooltip title="Runing behind reverse proxy">
            <CheckCircleOutlineOutlinedIcon style={{ color: green[500] }} />
        </Tooltip>
        :
        <Tooltip title="Running without reverse proxy">
            <ErrorOutlineIcon style={{ color: red[500] }} />
        </Tooltip>
}


const BackupsStatus = props => {

    return props.backups && props.backups > 0 ?
        <Tooltip title="Backups found">
              <Typography variant="body2" gutterBottom>{props.backups}</Typography>
        </Tooltip>
        :
        <Typography variant="body2" gutterBottom>No backups</Typography>
}



export default function ServerStatus(props) {

    const { server } = useContext(serverContext);
    const { dispatchApp } = useContext(appContext);
    const [uptime, setUptime] = useState('N/A');
    const [systemUptime, setSystemUptime] = useState('N/A');
    const [isRestartDlgOpen, setIsRestartDlgOpen] = useState(false);
    const [updated, setUpdated] = useState(Date.now());
    const { data: backups, hasError, isLoading } = useServerGet('backups/count', server, updated);

    useEffect(() => {
        const interval = setInterval(() => {
            if (server.serverStartTime != null) {
                const d = moment.duration(moment().diff(server.serverStartTime));
                if (d.asHours() > 24)
                    setUptime(d.humanize());
                else
                    setUptime(moment.utc(moment().diff(server.serverStartTime)).format('HH:mm:ss'));
            }
            else
                setUptime('-');

        }, 1000);
        return () => clearInterval(interval);
    }, [server.serverStartTime]);


    useEffect(() => {
        const interval = setInterval(async () => {

            try {
                const info = await fetchFromServer('systeminfo', server);
                const d = moment.duration(info.data.systemUptime * 1000);
                setSystemUptime(d.humanize());

            } catch (error) {
                setSystemUptime('-');
            }

        }, 5000);
        return () => clearInterval(interval);
    }, [server]);


    const handleOnUpdateClick = async ver => {
        console.log(`update to ${ver}`);
        if (props.onVersionUpdate)
            props.onVersionUpdate(ver);
    }

    function handleReset() {
        setIsRestartDlgOpen(true);
    }

    const handleClose = () => {
        setIsRestartDlgOpen(false);
    };

    const handleRestart = () => {
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Restarting the server...`,  severity: 'warning'  } });
        postToServer('shutdown?restart', {}, server);
        setIsRestartDlgOpen(false);
    };

    return (
        <React.Fragment>

            <Grid item container spacing={1}>
                <Grid item xs={6}>
                    <Title>Server</Title>
                </Grid>
                <Grid item xs={6}>
                    <ShowState state={server.state} />
                </Grid>
            </Grid>
            <Grid item container spacing={0} alignItems="center" >
                <Grid item xs={6} >
                    <Typography variant="body2" gutterBottom>Name</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">{server.serverName}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>Version</Typography>
                </Grid>
                <Grid item xs={6}>
                    <StServerVersion currentVersion={server.serverVersion} versionInfoUrl={'https://api.github.com/repos/impleotv/stserver-release/releases/latest'} showUpdateButtonIfNeeded={true} onUpdateClick={handleOnUpdateClick} />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>Server Uptime</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2">{uptime}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Tooltip title='Restart server'>
                        <IconButton aria-label="reset" onClick={handleReset} style={{ height: '24px', width: '24px' }}>
                            <PowerSettingsNewIcon size="small" color="secondary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>System Uptime</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">{systemUptime}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Link to="/backups" color="primary">
                        Backups
                    </Link>
                </Grid>
                <Grid item xs={6}>
                    <BackupsStatus backups={backups} />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>Reverse proxy</Typography>
                </Grid>
                <Grid item xs={6}>
                    <ReverseProxyStatus useReverseProxy={server.useReverseProxy} />
                </Grid>
            </Grid>

            <Dialog
                open={isRestartDlgOpen}
                TransitionComponent={Transition}
            >
                <DialogTitle id="restart-dialog-slide-title">

                </DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to restart?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRestart} color="primary">
                        Restart
                    </Button>
                    <Button onClick={handleClose} color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <div>
            <Link color="primary" href="#" onClick={preventDefault}>
                More info
            </Link>
        </div> */}
        </React.Fragment>
    );
}
