import React, { useState, useContext } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Title from './Title';
import { serverContext } from '../App';
import LicenseDlg from '../LicenseDlg';
import ShowLicenseDlg from './ShowLicenseDlg';
import { StJwtLicenseInfo } from 'stserver-frontend-comp';


export default function LicenseBrief() {
    const { server } = useContext(serverContext);
    const [licenseDlgOpen, setLicenseDlgOpen] = useState(false);
    const [licenseUpdateTime, setLicenseUpdateTime] = useState(false);
    const [showLicense, setShowLicense] = useState(false);

    const handleLicenseDlgClose = () => {
        setLicenseUpdateTime(new Date().toISOString());
        setLicenseDlgOpen(false);
    }


    function handleShowLicense() {
        setShowLicense(true);
    }



    return (
        <React.Fragment>
            <Title>License</Title>
            <Typography component="p" variant="h5">
                {server.demoMode ? 'Demo' : 'OK'}
            </Typography>
            <StJwtLicenseInfo serverUrl={server.serverHost} username={server.username} password={server.password} licenseInfoUpdateTime={licenseUpdateTime} />
            <Grid item container spacing={1}>
                <Grid item xs={6} sm={6}>
                    <div>
                        <Link color="primary" href="#" onClick={() => setLicenseDlgOpen(true)}>
                            Update License
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Tooltip title='Show license'>
                        <IconButton aria-label="showLicense" onClick={handleShowLicense} style={{ height: '24px', width: '24px' }}>
                            <FormatListBulletedIcon size="small" color="secondary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <LicenseDlg open={licenseDlgOpen} onClose={handleLicenseDlgClose} />
            {showLicense && <ShowLicenseDlg onCancel={() => setShowLicense(false)} />}
        </React.Fragment>
    );
}
