import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { serverContext } from './App.js';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { orange } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import packageJson from '../package.json';

const useStyles = makeStyles(theme => ({

    root: {
        maxWidth: 480,
    },
    media: {
        height: 0,
        paddingTop: '28%',
        marginTop: '30',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: orange[500],
    },
    version: {
        marginTop: 8,
        textAlign: "center", 
    }
}));

export default function About() {
    const { server } = useContext(serverContext);
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center">
            <Grid item xs={9}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="stserver" className={classes.avatar}>
                                S
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Stanag On Demand Server"
                        subheader="STANAG 4609 FMV"
                    />                   
                    <CardContent> 
                        <CardMedia
                            className={classes.media}
                            image="/impleo_logo_large.png"
                            title="IMPLEOTV SYSTEMS"
                        />
                        <Typography  className={classes.version} variant="caption" display="block" gutterBottom >
                            Frontend: {packageJson.version} / Backend: {server.serverVersion}
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <IconButton aria-label="share">
                            <InfoIcon />
                        </IconButton>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>
                                STANAG On Demand Server delivers On-Demand / Live FMV and geo-spatial metadata to video analysts and field operators in order to help them easily access, analyze, and present mission information. The server allows efficient review of vast amounts of ISR content using mission related and spatial queries.
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>

        </Grid>

    );
}
