
/**
 * platformsReducer
 * @param  {} platforms
 * @param  {} action
 */
const platformsReducer = (platforms, action) => {
  switch (action.type) {
    case 'SET_PLATFORMS':
      return {...platforms, availablePlatforms : [...action.payload]};

    case 'SET_PLATFORMS_UPDATE_TIME':
      return {...platforms, stateUpdateTime : action.payload};

    default:
      return platforms;
  }
};



export default platformsReducer;
