import React, { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { serverContext } from '../App';
import axios from 'axios';

const CountrySelect = props => {
    const { server } = useContext(serverContext);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
   
    const [selectedCountry] = useState(props.country_code ? {
        country: props.country_code,
        name: props.country_name
    } : undefined);


    useEffect(() => {
        let active = true;

         if (!loading) {
             return undefined;
         }

        (async () => {
            try {
                const res = await axios(
                    `${server.serverHost}/api/countryCodes`, {
                    headers: { 'Authorization': `Basic ${server.token}` }
                });

                if (active) {
                    setOptions(res.data);
                }
            } catch (error) {
                console.log(error.message);
            }    

        })();

        return () => {
            active = false;
        };
    }, [loading, server.serverHost, server.token]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            size="small"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            value={selectedCountry}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard" label="Country"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            onChange={(event, value, reason) => props.onChange(value, reason)}
        />
    );
}

export default CountrySelect;