import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { UserActivityTable } from 'stserver-frontend-comp';
import { serverContext } from './App.js';

export default function UserActivity() {

    const { server } = useContext(serverContext);

    return (
        <Grid container spacing={1}  >
            <Grid item xs={12} >
               <UserActivityTable serverUrl={server.serverHost}  username={server.username} password={server.password}/>
            </Grid>
        </Grid>
    );
}