import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter, useHistory } from 'react-router-dom';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { appContext, serverContext, missionsContext, platformsContext, fetchFromServer, translateMissions, getConfig, wsEmit } from '../App';


const useStyles = makeStyles(theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3) * 3}px`,
    },
    avatar: authenticated => authenticated ? {
        margin: theme.spacing(1),
        backgroundColor: green[500],
    } : {
            margin: theme.spacing(1),
            backgroundColor: red[500],
        },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        marginTop: theme.spacing(3)
    },
}));


function Login(props) {
    const { server, dispatchServer } = useContext(serverContext);
    const { dispatchApp } = useContext(appContext);
    const { dispatchMissions } = useContext(missionsContext);
    const { dispatchPlatforms } = useContext(platformsContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError /*, setUsernameError*/] = useState({});
    const [passwordError /*, setPasswordError*/] = useState({});
    const [authenticated, setAuthenticated] = useState(false);

    //  const [rememberMeChecked, setRememberMeChecked] = useState('');
    
    useEffect(() => {

        const fetchData = async () => {

            const serverCfg = await getConfig();

            const username = localStorage.getItem('username') || 'guest';
            setUsername(username);
            const password = localStorage.getItem('password') || 'guest';
            setPassword(password);

            fetchFromServer(`users/authenticated`, serverCfg)
                .then(() => {
                    setAuthenticated(true)
                })
                .catch(error => {
                    setAuthenticated(false);
                    localStorage.setItem('username', 'guest');
                    localStorage.setItem('password', 'guest');
                    getConfig();
                })
        }

        fetchData();

    }, []);

    const history = useHistory();

    const handleUsernameChange = async event => {
        const name = event.target.value.trim();
        setUsername(name);
    };

    const handlePasswordChange = event => {
        const pssw = event.target.value.trim();
        setPassword(pssw);
    };


    const handleLogin = async event => {
        event.preventDefault();
        try {

            if (username.length > 0) {

                server.token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
                await fetchFromServer(`users/authenticated`, server);

                setAuthenticated(true);
                localStorage.setItem('username', username);
                localStorage.setItem('password', password);
                const serverCfg = await getConfig();
                dispatchServer({ type: 'SET_SERVER_CFG', payload: serverCfg });
                dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `User ${username} authenticated.` } });               
                wsEmit('UpdateConnectedUser', username);

                const serverMissions = await fetchFromServer('missions/brief', serverCfg);
                dispatchMissions({ type: 'SET_MISSIONS', payload: translateMissions(serverMissions.data) });

                const groups = await fetchFromServer('usergroups', serverCfg);
                dispatchServer({ type: 'SET_USERGROUPS', payload: groups.data });

                const servePlatforms = await fetchFromServer('platforms', serverCfg);
                dispatchPlatforms({ type: 'SET_PLATFORMS', payload: servePlatforms.data });

                const retMessages = await fetchFromServer(`usermessages`, serverCfg);
                dispatchServer({ type: 'SET_USER_MESSAGES', payload: retMessages.data });

                history.push("/missions");
            }

        } catch (error) {
            setAuthenticated(false);
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: 'Username / password not valid', severity: 'error' } });
        }
    }

    // const handleRememberMeChange = event => {
    //     setRememberMeChecked(event.target.checked);
    // }

    const classes = useStyles(authenticated);

    return (
        <main className={classes.main} >
            <CssBaseline />
            <Paper className={classes.paper} >
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <form className={classes.form} >
                    <TextField id="username" name="username" label="Username" margin="normal" autoComplete="on" required fullWidth autoFocus
                        value={username}
                        error={usernameError.error}
                        helperText={usernameError.text}
                        onChange={handleUsernameChange} />

                    <TextField id="password" name="password" label="Password" margin="normal" autoComplete="off" type='password' required fullWidth
                        value={password}
                        error={passwordError.error}
                        helperText={passwordError.text}
                        onChange={handlePasswordChange} />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" checked={rememberMeChecked} onChange={handleRrememberMeChange} />}
                        label="Remember me"
                    /> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        component={Link}
                        to="/register"
                        className={classes.submit}>
                        Register
                    </Button>
                </form>
            </Paper>
        </main>
    );
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(Login);