import React, { useState, useContext } from 'react';
import { Typography, Paper, Avatar, Button, TextField } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom'
import { appContext, serverContext, fetchFromServer, postToServer } from '../App';
import CountrySelect from './CountrySelect';

const useStyles = makeStyles(theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
}));

const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function Register(props) {

    const { server } = useContext(serverContext);
    const { dispatchApp } = useContext(appContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userDetails, setUserDetails] = useState({ first_name: '', last_name: '', company: '' })
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [usernameError, setUsernameError] = useState({});
    const [emailError, setEmailError] = useState({});
    const [passwordError, setPasswordError] = useState({});
    const [registerDisabled, setRegisterDisabled] = useState(false);


    const handleUsernameChange = async event => {

        try {
            const name = event.target.value.trim();
            setUsername(name);

            if (name.length > 0) {
                const retExist = await fetchFromServer(`users/exists/${name}`, server);
                if (retExist.data) {
                    setUsernameError({ error: true, text: `User ${name} already exists` });
                    setRegisterDisabled(true);
                }
                else {
                    setUsernameError({ error: false, text: '' });
                    setRegisterDisabled(false);
                }
            }
        } catch (error) {

        }
    };

    const handleEmailChange = event => {
        const emailAddr = event.target.value.trim()
        setEmail(emailAddr);
        if (validateEmail(emailAddr))
            setEmailError({ error: false, text: '' });
        else
            setEmailError({ error: true, text: 'Not a valid email address' });
    };

    const handlePasswordChange = event => {
        const pssw = event.target.value.trim();
        setPassword(pssw);
        if (pssw.length === 0 || pssw.length >= 4)
            setPasswordError({ error: false, text: '' });
        else
            setPasswordError({ error: true, text: 'Password too short' });
    };

    const handleCountryChange = country => {
        if(country)
            setCountry(country.name);
    }

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            const data = {
                username: username,
                password: password,
                email: email,
                country: country,
                ...userDetails
            };

            const ret = await postToServer('users', data, server);
            if (ret.status === 200)
                dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `User ${username} added.` } });

        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error adding user ${username}: ${error.response.data}` } });
        }
    }

    const classes = useStyles();

    return (
        <main className={classes.main}>
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Register Account
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>

                    <TextField id="username" name="username" label="Username" autoComplete="on" required fullWidth autoFocus
                        value={username}
                        error={usernameError.error}
                        helperText={usernameError.text}
                        onChange={handleUsernameChange} />

                    <TextField id="first_name" name="first_name" label="First Name" autoComplete="on" required fullWidth value={userDetails.first_name} onChange={ev => {
                        setUserDetails({ ...userDetails, first_name: ev.target.value })
                    }
                    } />
                    <TextField id="last_name" name="last_name" label="Last Name" autoComplete="on" required fullWidth value={userDetails.last_name} onChange={ev => setUserDetails({ ...userDetails, last_name: ev.target.value })} />

                    <TextField id="email" name="email" label="Email" autoComplete="on" required fullWidth
                        value={email}
                        error={emailError.error}
                        helperText={emailError.text}
                        onChange={handleEmailChange} />

                    <CountrySelect onChange={handleCountryChange}/>

                    <TextField id="password" name="password" label="Password" autoComplete="off" type='password' required fullWidth
                        value={password}
                        error={passwordError.error}
                        helperText={passwordError.text}
                        onChange={handlePasswordChange} />

                    <TextField id="company" name="company" label="Organization" autoComplete="on" required fullWidth value={userDetails.company} onChange={ev => setUserDetails({ ...userDetails, company: ev.target.value })} />

                    <Button
                        type="submit"
                        disabled={registerDisabled}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Register
                    </Button>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to="/login"
                        className={classes.submit}>
                        Go back to Login
                    </Button>
                </form>
            </Paper>
        </main>
    )
}

export default withRouter(Register)