import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import * as moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 4,  
  },
  tooltip: {
    border: '1px dotted grey',
    opacity: 0.8,
    padding: 4,
    backgroundColor: 'white',
    fontSize: '11px'
  },
  slider: {
    marginLeft: 6,
    marginTop: -24,
    paddingBottom: 20,
    opacity: 0.6,
    width: '98%'
  },
  message: {
    marginLeft: 20,
    marginRight: 20,  
    color: "#2882F8",   
    verticalAlign: "middle",
    alignItems: "center",
    textAlign: "center"
  }
}));


const formatTime = t => {
  return moment.utc(moment.duration(t, "seconds").asMilliseconds()).format("HH:mm:ss");
}

const CustomTooltip = ({ payload, label, active }) => {
  const classes = useStyles();
  if (active && payload && payload.length > 0) {
    return (
      <div className={classes.tooltip}>    
        <p >{`Video pos: ${moment.utc(moment.duration(payload[0].payload.offset, "seconds").asMilliseconds()).format("HH:mm:ss")}`}</p>
        <p >{`${moment(payload[0].payload.start).format("MMMM Do, h:mm:ss a")}`}</p>       
      </div>
    );
  }

  return null;
}

export default function VideoCoverageChart(props) {
  const theme = useTheme();

  const [coverageData, setCoverageData] = useState(null);
  const [duration, setDuration] = useState(0);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [openChart, setOpenChart] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);
  const [openMessage, setOpenMessage] = useState({opened: false, message: ''});

  useEffect(() => {

    let prData = [];
    if (Array.isArray(props.data.coverage) && props.data.coverage.length > 0) {
      setOpenChart(true);
      const coverage = props.data.coverage;
      const startTime = moment(props.data.startTime);
      const endTime = moment(props.data.endTime);
      prData.push({ start: props.data.startTime, offset: 0, avg: 0 });
      const vCoverage = coverage.map(d => { return { start: d.start, offset: moment.duration(moment(d.start) - startTime).asSeconds(), avg: d.avg }});
      prData = [...prData, ...vCoverage];
      prData.push({ start: props.data.endTime, offset: moment.duration(endTime - startTime).asSeconds(), avg: 0 });
      setCoverageData( Object.assign({}, prData));
      setCoverageData(prData);
      setOpenSlider(true);
      setDuration(moment.duration(endTime - startTime).asSeconds());    
    }
    else
      setOpenMessage({opened: true, message: 'No relevant video found.'})

  }, [props.data])


  const handleClick = value => {

    if (props.onClick)
      props.onClick({
        offset: value.payload.offset,
        time: value.payload.start
      });
  }

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    props.onClick({
      offset: newValue,
      time: 0
    });
  }

  const classes = useStyles();
  return (

    <React.Fragment className={classes.root} >
      {openChart &&
        <ResponsiveContainer>
          <AreaChart data={coverageData}>
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#2E86C1" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0.05} />
              </linearGradient>
            </defs>
            <XAxis dataKey="offset" stroke={theme.palette.text.secondary} type="number" interval="0" domain={[0, 'dataMax']} tickFormatter={formatTime} />
            <Tooltip content={<CustomTooltip />} />
            <Area type="monotone" dataKey="avg" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" dot={{ onClick: handleClick, r: 4 }} />
          </AreaChart >
        </ResponsiveContainer>
      }
      {openSlider &&
        <Slider className={classes.slider}
          value={sliderValue}
          min={0}
          max={duration}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
        />}
      {openMessage.opened &&
        <Typography className={classes.message} variant="button" display="block" gutterBottom >
          {openMessage.message}
        </Typography>
      }
    </React.Fragment>
  );
}
