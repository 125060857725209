import React from "react";
import ReactCountryFlag from "react-country-flag";
import Tooltip from '@material-ui/core/Tooltip';

const CountryFlag = props => {

  return (
    props.country_code ? 
    <Tooltip title={props.country_name || props.country_code}>
      <ReactCountryFlag countryCode={props.country_code} svg />
    </Tooltip>
    : null
  )
}


export default CountryFlag;