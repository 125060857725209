import update from 'immutability-helper';



export const securityClassificationMap = {
  'UNCLASSIFIED': 1,
  'RESTRICTED': 2,
  'CONFIDENTIAL': 3,
  'SECRET': 4,
  'TOP SECRET': 5
};

export const securityClassificationLookup = {
  1: 'UNCLASSIFIED',
  2: 'RESTRICTED',
  3: 'CONFIDENTIAL',
  4: 'SECRET',
  5: 'TOP SECRET'
};


export const userRoleMap = {
  // 'superAdmin': 1,
  'admin': 2,
  'groupAdmin': 3,
  'user': 4,
  'guest': 5
};


export const userRoleLookup = {
  // 1: 'superAdmin',
  2: 'admin',
  3: 'groupAdmin',
  4: 'user',
  5: 'guest'
};


/**
 * serverReducer
 * 
 * @param  {} server
 * @param  {} action
 */
const serverReducer = (server, action) => {
  switch (action.type) {

    case 'SET_SERVER_CFG':
      const payload = action.payload;

      return update(server, {
        token: { $set: payload.token },
        username: { $set: payload.username },
        password: { $set: payload.password },
        clientId: { $set: payload.clientId },
        clientIp: { $set: payload.clientIp },
        userRole: { $set: payload.userRole },
        serverHost: { $set: payload.serverHost },
        serverUrl: { $set: payload.serverUrl },
        serverVersion: { $set: payload.serverVersion },
        serverName: { $set: payload.serverName },
        serverStartTime: { $set: new Date(payload.serverStartTime) },
        demoMode: { $set: payload.demoMode },
        nodeInfo: { $set: payload.nodeInfo },
        mqtt: { $set: payload.mqtt },
        videoDir: { $set: payload.videoDir },
        supervisorUrl: { $set: payload.supervisorUrl },
        useReverseProxy: { $set: payload.useReverseProxy },
        useWebRtcVideo: { $set: payload.useWebRtcVideo },
        useLiveRtspHls: { $set: payload.useLiveRtspHls },
        useLiveLowLatency: { $set: payload.useLiveLowLatency },
        webRtcService: { $set: payload.webRtcService },
        playbackDataDelay: { $set: payload.playbackDataDelay },
        browserInfo: { $set: payload.browserInfo },   
        conferenceServerUrl:  { $set: payload.conferenceServerUrl },     
        conferenceServerPort:  { $set: payload.conferenceServerPort },     
        lk_url:  { $set: payload.lk_url },    
      });

    case 'SET_SERVER_SOCKET':

      return update(server, {
        socket: { $set: action.payload }
      });

    case 'UPDATE_LICENSE_VALID':
      const lic = action.payload;
      return update(server, {
        demoMode: { $set: !lic.valid }
      });

    case 'SET_SERVER_STATE':
      // update state and set serverStartTime to null in case the server is offline
      return update(server, { state: { $set: action.payload }, serverStartTime: { $apply: () => { return action.payload.toLowerCase() === 'offline' ? null : server.serverStartTime } } });

    case 'UPDATE_SERVER_UPTIME':
      return update(server, { serverStartTime: { $set: action.payload } });

    case 'SET_USERGROUPS':
      return update(server, { usergroups: { $set: action.payload } });

    case 'SET_USERS':
      return update(server, { users: { $set: action.payload } });

    case 'SET_ONLINE_USERS':
      return update(server, { onlineUsers: { $set: action.payload } });

    case 'SET_USER_MESSAGES':
      return update(server, { usermessages: { $set: action.payload } });

    case 'SET_CONFERENCES_COUNT':
        return update(server, { conferencesCount: { $set: action.payload } });  

    case 'LIVE_STREAM_EVENTS':
      if(server && server.socket) {
        server.socket.emit('LiveStreamEvents', action.payload );
      }      
      return server;



    default:
      return server;
  }
};

export default serverReducer;
