import React, { useState, useEffect, forwardRef, createContext, useReducer } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import CreateMissionStepper from './CreateMissionStepper';
import MissionGeneralForm from './Components/MissionGeneralForm';
import MissionPresentationForm from './Components/MissionPresentationForm';
import MissionSensorsForm from './Components/MissionSensorsForm';
import { makeStyles } from '@material-ui/core/styles';
import update from 'immutability-helper';
import clsx from 'clsx';


export const newMissionContext = createContext(null);

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: '6px',
    },
    paper: {
        padding: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        backgroundColor: '#FAFAFA'
    },
    fixedHeight: {

    },
}));


// const Line = ({ color }) => (
//     <hr
//         style={{
//             color: color,
//             backgroundColor: color,
//             height: 1,
//             borderStyle: 'none'
//         }}
//     />
// );


const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" {...props} ref={ref} />;
})

const ActiveForm = ({ step, editMode }) => {

    if (step === 0)
        return <MissionGeneralForm editMode={editMode}/>
    else
        if (step === 1)
            return <MissionPresentationForm editMode={editMode} />
        else
            if (step === 2)
                return <MissionSensorsForm editMode={editMode} />
            else
                return null;
}


const defaultMission = {
    name: 'NewMission',
    description: 'Description',
    usergroups: [],
    securityClassification: 'UNCLASSIFIED',
    platform: {
        name: '-',
        presentation: {
            color: '#004dcf',
            type: 'Plane'
        }      
    },
    sensors: [
        {
            name: 'EO/IR',
            description: 'Sensor description',
            type: 'video',
            files: []
        }
    ]
}

const getLastEditedMission = () => {
    const lastStoredMission = localStorage.getItem('lastMission');
    const lastMission = lastStoredMission != null ? JSON.parse(lastStoredMission) : defaultMission;
    return lastMission;
}


const newMissionReducer = (mission, action) => {
    switch (action.type) {

        case 'SET_MISSION':
            return update(mission, { $set: action.payload });

        case 'SET_NAME':
            return update(mission, { name: { $set: action.payload } });

        case 'SET_DESCRIPTION':
            return update(mission, { description: { $set: action.payload } });

        case 'SET_USERGROUPS':
            return update(mission, { usergroups: { $set: action.payload } });

        case 'SET_SEC_CLASSIFICATION':
            return update(mission, { securityClassification: { $set: action.payload } });

        case 'SET_PLATFORM_NAME':
            const mi = update(mission, { platform: { name: { $set: action.payload } } }); 
            return mi;

        case 'SET_PLATFORM_TYPE':
            return update(mission, { platform: { type: { $set: action.payload } } });

        case 'SET_PLATFORM_COLOR':
            return update(mission, { platform: { presentation: {color: { $set: action.payload } } } });

        case 'SET_SENSORS':
            return update(mission, { sensors: { $set: action.payload } });
            
        case 'SET_RATING':
            return update(mission, { rating: { $set: action.payload } });  

        case 'SET_COUNTRY':
            return update(mission, { country_name: { $set: action.payload.country_name }, country_code: { $set: action.payload.country_code } } );  

        case 'SET_TAGS':
             return update(mission, { tags: { $set: action.payload } });  

        case 'SET_MISSION_SORT_ORDER':
            return update(mission, { sortOrder: { $set: action.payload } });  

        default:
            return mission;
    }
};



/**
 * @param  {} props
 * open 
 * editMode
 * mission      - mission mode
 * platform     - platform mode. This mode is used when we have to create live mission for the selected platform. Only some of the field can be edited.
 * onClose
 * onCreateMission
 */
function CreateMissionDlg(props) {

    const [newMission, dispatchNewMission] = useReducer(newMissionReducer, props.editMode ? props.mission : getLastEditedMission());
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (props.editMode) {        
            dispatchNewMission({ type: 'SET_MISSION', payload: props.mission });
        }
        else {
            const lastMission = getLastEditedMission();
            lastMission.sensors.forEach(s => s.files = []);
            dispatchNewMission({ type: 'SET_MISSION', payload: lastMission });
        }
    }, [props.editMode, props.mission])

    const handleCreateMission = () => {

        delete newMission._id;
        delete newMission.creationTime;
        delete newMission.startTime;
        delete newMission.endTime;
        delete newMission.thumbnail;
        delete newMission.serverId;
        delete newMission.serverVer;

        // if it is for live platform
        if (props.platform) {
            // TODO: Finish dev
            // const checkedSensors = props.platform.sensors.filter(s=>s.checked);
            // newMission.sensors = checkedSensors.map(s => s.sensorName)
            newMission.sensors = props.platform.sensors.map(s => s.sensorName)
            newMission.platform.type = props.platform.platformType;
        }
        else {
            const template = JSON.parse(JSON.stringify(newMission));
            template.name = template.name.concat('-');
            template.sensors = [];
            template.sensors.push({
                name: 'EO-IR',
                description: 'Sensor',
                type: 'video',
                files: []
            });

            localStorage.setItem('lastMission', JSON.stringify(template));
        }

        if (props.onCreateMission && !props.editMode)
            props.onCreateMission(newMission);
        else
            if (props.onUpdateMission && props.editMode)
                props.onUpdateMission(newMission);
    }


    const handleEnter = () => {
        if (!props.editMode)
            setActiveStep(0);
    }

    
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (

        <newMissionContext.Provider value={{ newMission, dispatchNewMission }}>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted= {false}
                onClose={() => props.onClose()} 
                onEntered={handleEnter}
                aria-labelledby="mission-dialog-slide-title"
                aria-describedby="mission-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle id="mission-dialog-slide-title">
                    {props.editMode ? "Edit mission" : "Create Mission"}
                </DialogTitle>
                <DialogContent>
                    {props.mission !== undefined &&   //only show for mission (hide for live mission creation for platform)
                         <DialogContentText id="alert-dialog-slide-description">
                            <Paper elevation={1} className={fixedHeightPaper}>
                                <CreateMissionStepper onStep={step => setActiveStep(step)} editMode={props.editMode} />
                            </Paper>
                        </DialogContentText>
                    }                   

                    <Grid container className={classes.content} justify="space-around">
                        <Grid item xs={12}>
                            <ActiveForm step={activeStep} editMode={props.editMode}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleCreateMission()} color="primary" disabled={!props.editMode && props.platform === undefined && (newMission === null || newMission.sensors.length === 0 || newMission.sensors[0].files.length === 0)}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </newMissionContext.Provider>

    );
}

export default CreateMissionDlg;