import React, { useState, useContext, useEffect, forwardRef } from 'react';
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import { serverContext, appContext, putToServer, callServer } from '../App';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { grey, green } from '@material-ui/core/colors';
import moment from 'moment';
import { useGetUsergroups } from '../customEffects';
import SelectUsergroups from './SelectUsergroups'
import { securityClassificationMap, securityClassificationLookup, userRoleMap, userRoleLookup } from '../serverReducer';


const tableIcons = {
  AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#47A3E8',
    },
  }
});

const useStyles = makeStyles(theme => ({
  user: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(4),
    color: 'grey',
  },
  select: {
    minwidth: 240,
    maxWidth: 600,
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 40,
    minWidth: 240,
    maxWidth: 600,
  },
  formControlLabel: {
    color: 'grey',
  },
  offline: {
    backgroundColor: grey[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  online: {
    backgroundColor: green[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));


const StatusAvatar = props => {
  const classes = useStyles();

  const { username, onlineCount } = props;

  return (
    <div className={classes.root}>
      <Badge badgeContent={onlineCount} color="secondary" >
        <Avatar className={onlineCount > 0 ? classes.online : classes.offline}>{username.charAt(0).toUpperCase()}</Avatar>
      </Badge>
    </div>
  );
}

const translateUsers = users => {

  if (!Array.isArray(users))
    return [];

  let tableUsers;
  try {
    let i = 0;
    tableUsers = users.map(u => {
      return {
        id: i++,
        userId: u._id,
        username: u.username,
        firstName: u.firstName,
        lastName: u.lastName,
        onlineCount: u.onlineCount,
        email: u.email,
        authorizationRole: u.authorizationRole === 'superAdmin' ? 'superAdmin' : userRoleMap[u.authorizationRole],
        securityClearance: securityClassificationMap[u.securityClearance],
        usergroups: u.usergroups,
        usergroupsAdmin: u.usergroupsAdmin,
        country: u.country,
        registered: u.registered,
        lastAccess: u.lastAccess
      };
    });

  } catch (error) {

  }

  return tableUsers;
}

const UsersTableTitle = () => {
  return (
    <div style={{ display: 'flex' }}>
      <Typography noWrap variant="overline" display="block">
        Users
      </Typography>
    </div>
  )
}

/**
 * table columns
 *  @param  {} cb
 */
const columns = [
  { title: '', field: 'onlineCount', tooltip: 'Status', editable: 'never', width: '4%', render: rowData => { return <StatusAvatar username={rowData.username} onlineCount={rowData.onlineCount} /> } },
  { title: 'Username', field: 'username', tooltip: 'Username', editable: 'never' },
  { title: 'First Name', field: 'firstName', tooltip: 'First name', hidden: window.innerWidth < 960 },
  { title: 'Last Name', field: 'lastName', tooltip: 'Last name', hidden: window.innerWidth < 960 },
  { title: 'Email', field: 'email', tooltip: `Sender's email`, width: '15%', hidden: window.innerWidth < 960, render: rowData => { return (rowData.email !== undefined ? `${rowData.email}` : 'N/A') } },
  { title: 'Role', field: 'authorizationRole', tooltip: "User's role", lookup: userRoleLookup },
  { title: 'Groups', field: 'usergroups', tooltip: "User groups", editable: 'never', render: rowData => { return rowData.usergroups ? <Tooltip title={rowData.usergroups.map(ug => ug.name).join(', ')}><div>{rowData.usergroups.length}</div></Tooltip> : '0' } },
  { title: 'Country', field: 'country', tooltip: `User's country`, render: rowData => { return (rowData.country !== undefined ? `${rowData.country}` : 'N/A') } },
  { title: 'Clearance', field: 'securityClearance', tooltip: `User's security classification`, width: '12%', lookup: securityClassificationLookup },
  { title: 'Registered', field: 'registered', tooltip: 'User registration date', editable: 'never', hidden: window.innerWidth < 960, render: rowData => { return moment(rowData.registered).format("MMM Do YY, hh:mm") } },
  { title: 'Last access', field: 'lastAccess', tooltip: 'Last access', editable: 'never', hidden: window.innerWidth < 960, render: rowData => { return moment(rowData.lastAccess).format("MMM Do YY, hh:mm") } },
];



/**
 * 
 * @param {Object} props 
 */
const UsersTable = props => {

  const { server } = useContext(serverContext);
  const { dispatchApp } = useContext(appContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);

  useGetUsergroups();

  useEffect(() => {

    if (server.users)
      setData(translateUsers(server.users));

  }, [server.users, server.usergroups])


  const actions = [
    {
      icon: tableIcons.DeleteIcon,
      tooltip: 'Delete User(s)',
      onClick: async (event, data) => {

        try {
          const userNames = data.map(u => u.username);
          let userNamesStr; 
          
          if(userNames.length === 1)
            userNamesStr = userNames[0];
          else
            if( userNames.length > 1 ) {
              userNamesStr = userNames.join(',');
            }             
       
          await callServer('delete', `users?users=${userNamesStr}`, undefined, server);
        } catch (error) {
          dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error deleting user(s): ${error.response ? error.response.data : error.message}` } });
        }
      }
    }
  ];

  const handleUserGroupsChange = async (userId, ugs) => {

    try {
      const usergroups = ugs.map(g => g._id);
      await putToServer(`users/${userId}?` +
        `&usergroups=${usergroups}`,
        {}, server);

    } catch (error) {
      dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error updating user: ${error.response ? error.response.data : error.message}` } });
    }
  }

  const handleUserGroupsAdminChange = async (userId, ugs) => {

    try {
      const usergroups = ugs.map(g => g._id);
      await putToServer(`users/${userId}?` +
        `&usergroupsAdmin=${usergroups}`,
        {}, server);

    } catch (error) {
      dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error updating user: ${error.response ? error.response.data : error.message}` } });
    }
  }

  const handleRowUpdate = (newData, oldData) => {
    return new Promise(async (resolve, reject) => {
      try {

        const res = await putToServer(`users/${newData.userId}?` +
          `&firstName=${newData.firstName}` +
          `&lastName=${newData.lastName}` +
          `&company=${newData.company}` +
          `&email=${newData.email}` +
          `&country=${newData.country}` +
          `&securityClearance=${securityClassificationLookup[newData.securityClearance]}` +
          `&authorizationRole=${userRoleLookup[newData.authorizationRole]}`,
          {}, server);

        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: res.data } });
        resolve();
      } catch (error) {
        dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error updating user: ${error.response ? error.response.data : error.message}` } });
        reject();
      }
    })
  }

  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable className={classes.root}
        icons={tableIcons}
        columns={columns}
        data={data}
        title={<UsersTableTitle />}
        detailPanel={rowData => {
          console.log(rowData.username);
          const userId = rowData.userId;
          const uGroups = rowData && Array.isArray(rowData.usergroups) ? rowData.usergroups : [];
          const uGroupsAdmin = rowData && Array.isArray(rowData.usergroupsAdmin) ? rowData.usergroupsAdmin : [];

          return (
            <div>
              <FormControl className={classes.formControl}>
                <Tooltip title='Assign allowed usergroups '>
                  <FormControlLabel
                    label={<Typography className={classes.formControlLabel}>Usergroups:&nbsp;</Typography>} labelPlacement="start"
                    control={ rowData.authorizationRole !== 2 && rowData.authorizationRole !== 'superAdmin'  ?  <SelectUsergroups selectedUserGroups={uGroups} onUserGroupsChange={ugs => handleUserGroupsChange(userId, ugs)}/> : <Typography>&nbsp;&nbsp;All</Typography> }
                  />
                </Tooltip>
              </FormControl>
              {
                rowData.authorizationRole === userRoleMap['groupAdmin'] &&
                <FormControl className={classes.formControl}>
                  <Tooltip title='Assign usergroups that user can control'>
                    <FormControlLabel
                      label={<Typography className={classes.formControlLabel}>Group Admin:</Typography>} labelPlacement="start"
                      control={<SelectUsergroups canDeleteDemo selectedUserGroups={uGroupsAdmin} onUserGroupsChange={ugs => handleUserGroupsAdminChange(userId, ugs)} />}
                    />
                  </Tooltip>
                </FormControl>
              }
            </div>
          )
        }}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow))}
        options={{
          selection: true,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EFFBFF' : '#FFF',
          }),
          headerStyle: {
            backgroundColor: '#EEE',
          }
        }}
        actions={actions}
        editable={{
          isEditable: rowData => rowData.username !== "guest" && rowData.username !== "superAdmin",
          isDeletable: rowData => rowData.username !== "guest",
          onRowUpdate: handleRowUpdate
        }}
      />
    </MuiThemeProvider>
  );
}

export default UsersTable; 
