import React, { forwardRef, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BookmarksTable from './Components/BookmarksTable';



const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" {...props} ref={ref} />;
})



export default function BookmarksDlg(props) {

    useEffect(()=>{

    }, [props.bookmarksUpdateTime])

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true} maxWidth={'lg'}
            open={true}
            TransitionComponent={Transition}
            onClose={() => props.onClose()}
        >
            <DialogContent>
                <BookmarksTable {...props} admin={true} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

