import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SensorTable from './SensorTable';
import { newMissionContext } from '../CreateMissionDlg';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
}));

export default function MissionSensorsForm(props) {
    const classes = useStyles();
    const { newMission, dispatchNewMission } = useContext(newMissionContext);

    const handleSetSensors = s => {

        dispatchNewMission({ type: 'SET_SENSORS', payload: s });
    }


    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <SensorTable admin editMode={props.editMode} sensors={newMission.sensors} onChange={handleSetSensors} />
            </div>
        </form>
    );
}
