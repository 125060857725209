import React, { useState, useContext, forwardRef, useEffect } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { serverContext } from '../App';
import Popover from '@material-ui/core/Popover';
import MaterialTable from 'material-table'
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as moment from 'moment';
import ColorPicker from './colorPicker';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { useSensorBookmarks } from 'stserver-frontend-comp';
import { useFetchImageFromVideoServer } from '../customEffects';

const tableIcons = {
  AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#47A3E8',
    },
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      //       boxShadow: 'none'
    }
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  mediaRootThumbnail: {
    width: 160,
  },
  mediaRootInfo: {
    width: 260,
  },
  media: {
    height: 120,
  },
  tagsFilter: {
    display: 'flex',
    color: 'blue',
    padding: '0px 10px'
  },

}));


export const BookmarkCell = props => {
  const { state, missionName, serverUrl, thumbnail, videoServerToken, update } = props;
  const { isLoading, hasError, image } = useFetchImageFromVideoServer(`${serverUrl}/videos/${thumbnail}`, videoServerToken, update);

  const classes = useStyles();

  return (thumbnail ?
    <Avatar variant="rounded" className={classes.small} alt={`${thumbnail}`} src={image} />
    :
    <Avatar className={classes.small}>R</Avatar>);
}

const BookmarkPopover = props => {
  const classes = useStyles();
  const { missionName, serverUrl, thumbnail, videoServerToken, update} = props;
  const { isLoading, hasError, image } = useFetchImageFromVideoServer(`${thumbnail}`, videoServerToken, update);

    return (
    <Card className={classes.mediaRootThumbnail}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={image}
        />
      </CardActionArea>
    </Card>
  );
}



/**
 * @param  {} props
 * admin - open in admin mode
 */
const BookmarksTable = props => {

  const { server } = useContext(serverContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [thumbnail, setThumbnail] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const curSensorParts = props.currentSensor.split('/');
  const [data, setData] = useState(Array.isArray(props.bookmarks) ? props.bookmarks : []);
  const { isLoading, bookmarks } = useSensorBookmarks(server.serverHost, curSensorParts[0], curSensorParts[1], server.token, props.bookmarksUpdateTime);

  const classes = useStyles();
  const actions = [];

  useEffect(() => {    
      setData(bookmarks.bookmarks);
  }, [bookmarks, bookmarks.bookmarks]) 

  if (props.admin) {
    // actions.push({
    //   icon: tableIcons.Edit,
    //   tooltip: 'Edit Bookmark',
    //   isFreeAction: true,
    //   onClick: event => {
    //     // if (checkIfOnline(server, dispatchApp)) {
    //     //   if (props.onEditMission)
    //     //     props.onEditMission(selectedRow);
    //     // }
    //   }
    // })
    actions.push({
      icon: tableIcons.DeleteIcon,
      tooltip: 'Delete Bookmark(s)',
      onClick: (event, data) => {
        if (props.onDeleteBookmarks) {
          props.onDeleteBookmarks(data);
        }
      }
    });
  }


  const handleSelectedRow = (evt, selectedRow) => {

    setSelectedRow(selectedRow);  
  }

  const handlePopoverOpen = (event, url) => {
    try {
      setThumbnail({
        mission: event.currentTarget.attributes.mission.value,
        thumbnail: url,
        accessToken: event.currentTarget.attributes.accessToken?.value
      });
      setAnchorEl(event.currentTarget);      
    } catch (error) {
      
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  const getColumns = () => {

    const videoDir = `${server.serverHost}/videos/`;
    return (
      [
        {
          field: 'thumbnailUrl', width: '5%', filtering: false,
          render: rowData =>
            <div
              mission={`${rowData.missionName}`}
              accessToken={bookmarks.missionAccessToken}
              onMouseEnter={event => handlePopoverOpen(event, `${videoDir}${rowData.thumbnailUrl}`)}
              onMouseLeave={handlePopoverClose}>
              {/* <Avatar variant="rounded" className={classes.small} src={`${videoDir}${rowData.thumbnailUrl}`} /> */}
              <BookmarkCell missionName={rowData.missionName} serverUrl={server.serverHost} thumbnail={rowData.thumbnailUrl} videoServerToken={bookmarks.missionAccessToken} update={rowData.update} />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <BookmarkPopover serverUrl={server.serverHost} missionName={thumbnail.mission} thumbnail={thumbnail.thumbnail} videoServerToken={thumbnail.accessToken} update={rowData.update}/>
              </Popover>
            </div>
        },
        {
          title: 'Title', field: 'title', render: rowData =>
            <Tooltip title={`Created on ${moment(rowData.created).format("MMM DD YYYY, h:mm")}`}>
              <strong>{`${rowData.title}`}</strong>
            </Tooltip>
        },
        { title: 'Text', field: 'text', width: '30%' },
        {
          title: 'Position', field: 'position', width: '10%', render: rowData =>
            <Tooltip title={`${rowData.position} sec`}>
              <Typography  >
                {`${moment.utc(moment.duration(rowData.position, "seconds").asMilliseconds()).format("HH:mm:ss")}`}
              </Typography>
            </Tooltip>
        },
        {
          title: 'Time', field: 'startTime', render: rowData =>
            moment(rowData.startTime).format("MMM DD YYYY, h:mm")
        },
        { title: 'Editor', field: 'editor', width: '5%' },
        {
          title: 'Color', field: 'color', width: '5%', hidden: window.innerWidth < 960, render: rowData =>
            <ColorPicker color={rowData.color} editDisabled={true} />
        },
      ]
    )
  }



  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <MaterialTable className={classes.root}
          icons={tableIcons}
          columns={getColumns()}
          data={data}
          title={'Bookmarks'}
          onRowClick={handleSelectedRow}
          options={{
            sorting: true,
            cellStyle: { padding: '0.4em' },
            headerStyle: { padding: '0.4em' },
            selection: props.admin,
            rowStyle: rowData => ({
              backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }}
          actions={actions}
        />
        {isLoading && <LinearProgress />}
      </MuiThemeProvider>
    </div>
  );
}


export default BookmarksTable;