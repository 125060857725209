import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { StMissionTimeline } from 'stserver-frontend-comp';
import { serverContext } from '../App';

const useStyles = makeStyles(theme => ({
    root: {            
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    paper: {
        width: '100%',
        height: '100%',       
        border: "solid 1px #ddd",
        borderRadius: '8px'
    },
}));



/**
 * Timeline renders the timeline on the available missions
 * 
 * TODO: Add timeline
 * @param  {} props
 */
function CurrentMissionTimeline(props) {
    const { server } = useContext(serverContext);
    const classes = useStyles();

    return (
            <Grid container className={classes.root} spacing={2}>
                <Grid item justify="center" spacing={2} className={classes.paper}>                   
                  <StMissionTimeline serverUrl={server.serverHost} missionIdOrName={props.missionName} curPos={props.curPos} username={server.username} password={server.password} serverToken={server.token} gridOpacity={0.24} bookmarksUpdateTime={props.bookmarksUpdateTime} onMouseCrosshairDown={props.onTimelineCrosshairClick} onBookmarkClick={props.onBookmarkClick}/>                
                </Grid>
            </Grid>
    );
}

export default CurrentMissionTimeline;