import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(1),
    },
}));

function Alert(props) {
    let p = {...props};
    p.variant = p.variant || "filled";
    return <MuiAlert elevation={4} {...p} />;
}


const MessageSnackbar = props => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (props.onHandleMessageSnackbarClose)
            props.onHandleMessageSnackbarClose();
    };

    const classes = useStyles();
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={props.open && (props.message.length > 0)}
                autoHideDuration={4000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert onClose={handleClose} variant={props.variant} severity={props.severity}>
                    {props.message}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default MessageSnackbar;