import React, {useState} from "react";
import { Rnd } from "react-rnd";



const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#f0f0f0",
    zIndex: 999
  };


const FloatingWnd = props => {

    const [state, setState] = useState(props.location);

    return (
        <Rnd
            style={style}
            size={{ width: state.width, height: state.height }}
            position={{ x: state.x, y: state.y }}
            onDragStop={(e, d) => {
                const loc = { ...state, x: d.x, y: d.y };
                setState(loc);
                if (props.onLocationChange)
                    props.onLocationChange(loc);
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                const loc = {
                    width: ref.style.width,
                    height: ref.style.height,
                    ...position
                };
                setState(loc);
                if (props.onLocationChange)
                    props.onLocationChange(loc);
            }}       
            disableDragging={!props.draggable}  
        >
            {props.children}
        </Rnd>)
}


  export default FloatingWnd;